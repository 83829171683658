/* Bootstrap overrides
-------------------------------------------------- */
$body: #000;
$headings-font-weight: 300 !important;
$primary: #1b46c2;
$light: #fafafa;

/* Do not move the imports from here. 
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import './../../../node_modules/bootstrap/scss/bootstrap.scss';

// Dapp-utils
//@import './../../../node_modules/@elrondnetwork/dapp-utils/dist/dapp-utils.scss';

/* Base
-------------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Outfit');
body {
  font-family: 'Outfit', sans-serif;
}
html {
  min-height: 100%;
  font-size: 14px;
}

.wrapper {
  min-height: 100vh;
}

.h2 {
  color: #111;
}

.opacity-6 {
  opacity: 0.6;
}
.span-title{
  color: $primary;
  font-weight: 700;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 259px;
  height: 32px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }
      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }
      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }
      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $white;

      &:hover {
        color: $white;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}

// Custom

[data-testid='walletLink']:nth-of-type(even) {
  display: none;
}
.card {
  background-color: rgba(44, 43, 43, 0.4);
}

a {
  color: $primary;
}
.a1 {
  color: rgb(0, 0, 0);
}

.bg-light,
.bg-white,
button {
  border: none !important;
  outline: none !important;
  background: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255);
}

.mint-btn {
  color: rgb(255, 255, 255);
  margin: 1rem 0;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}

main {
  background-image: url('../img/bg.png') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;

  .mint-root {
    border-radius: 0%;
    margin-top: 35rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .referral-container {
    margin-top: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: top;
  }

  .referral-code-title {
    margin-top: 1rem;
    color: $primary;
  }

  .mint-container {
    border-radius: 0%;
    margin-top: 18rem;
    //background-color: rgba(176, 242, 36, 1) !important;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .info-title {
      padding: 0.25rem;
      color: rgb(0, 0, 0) !important;
    }
    .referral-code {
      color: rgb(255, 255, 255);
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
    }

    .change-qty {
      color: rgb(255, 255, 255);
      padding: 1rem;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: 1rem;
    }
  }
}

.card.my-4.text-center,
.home-login {
  //background: rgba(0, 0, 0, 0.7);
  font-size: 1.4rem;
}

.nft-access-loading {
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  padding: 0.5rem;
  color: rgb(255, 255, 255) !important;
}

.center {
  margin: auto;
  width: 95%;
  border: 3px solid white;
  padding: 10px;
}

.unlock-page_title{
  color: #000 !important;
}

.unlock-page_description{
  color: #000 !important;
}
.transactions-toast-list_title{
  color: #000 !important;
}
.tx-details_statusTransactions{
  color: #000 !important;
}
.hidden-text-ref{
  color: #000 !important;
}
.transactions-toast-list_closeButton{
  background-color: white !important;
}

.dapp-core-ui-component .toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.dapp-core-ui-component .toast.showing {
  opacity: 1;
}
.dapp-core-ui-component .toast.show {
  display: block;
  opacity: 1;
}
.dapp-core-ui-component .toast.hide {
  display: none;
}
.dapp-core-ui-component .toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .toast-body {
  padding: 0.75rem;
}

.dapp-core-ui-component .toast-messages {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 9999;
}
@media (min-width: 768px) {
  .dapp-core-ui-component .toast-messages {
    right: 2rem;
    bottom: 2rem;
    width: 22.5rem;
    left: auto;
  }
}
.dapp-core-ui-component .toast-messages .toast {
  display: block;
  flex-basis: 4rem;
  width: 100%;
  max-width: 28.875rem;
  transition: transform 0.3s ease-in-out;
  transform: translateX(120%);
  background-color: #fff;
  border-radius: 0.25rem;
  border-color: #dee2e6;
}
.dapp-core-ui-component .toast-messages .toast.clickable {
  cursor: pointer;
}
.dapp-core-ui-component .toast-messages .toast.toast-visible {
  margin-bottom: 0.3rem;
  transform: translateX(0);
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress {
  background-color: transparent;
  height: inherit;
  font-size: inherit;
  line-height: inherit;
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress .progress-bar {
  background-color: rgba(108, 117, 125, 0.2);
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress .progress-bar .content-height {
  z-index: -1;
  visibility: hidden;
  width: 0 !important;
}
@media (max-width: 767.98px) {
  .dapp-core-ui-component .toast-messages .toast {
    display: none;
  }
  .dapp-core-ui-component .toast-messages .toast:first-child {
    display: block;
    transform: translateY(150%);
  }
  .dapp-core-ui-component .toast-messages .toast:first-child.toast-visible {
    transform: translateY(0);
  }
}
.dapp-core-ui-component .toast-messages .toast .tx-description {
  margin-bottom: 0.2rem;
}
.dapp-core-ui-component .toast-messages .close {
  opacity: 1;
}
.dapp-core-ui-component .toast-messages .close svg {
  color: #6c757d;
}

.dapp-core-ui-component progress {
  vertical-align: baseline;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.dapp-core-ui-component .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1b46c2;
  transition: width 0.6s ease;
}

@charset "UTF-8";
.dapp-core-ui-component {
  display: contents;
  /*!
   * Bootstrap v4.6.0 (https://getbootstrap.com/)
   * Copyright 2011-2021 The Bootstrap Authors
   * Copyright 2011-2021 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
  /* Bootstrap overrides
  -------------------------------------------------- */
  /* Do not move the imports from here.
  Override Bootstrap variables only above.
  -------------------------------------------------- */
  /* Base
  -------------------------------------------------- */
  /* Navbar
  -------------------------------------------------- */
  /* Dashboard
  -------------------------------------------------- */
  /* Loader, Transaction Success/Fail
  -------------------------------------------------- */
}
.dapp-core-ui-component :root {
}
.dapp-core-ui-component *,
.dapp-core-ui-component *::before,
.dapp-core-ui-component *::after {
  box-sizing: border-box;
}
.dapp-core-ui-component html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dapp-core-ui-component article, .dapp-core-ui-component aside, .dapp-core-ui-component figcaption, .dapp-core-ui-component figure, .dapp-core-ui-component footer, .dapp-core-ui-component header, .dapp-core-ui-component hgroup, .dapp-core-ui-component main, .dapp-core-ui-component nav, .dapp-core-ui-component section {
  display: block;
}
.dapp-core-ui-component body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.dapp-core-ui-component [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
.dapp-core-ui-component hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.dapp-core-ui-component h1, .dapp-core-ui-component h2, .dapp-core-ui-component h3, .dapp-core-ui-component h4, .dapp-core-ui-component h5, .dapp-core-ui-component h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dapp-core-ui-component p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dapp-core-ui-component abbr[title],
.dapp-core-ui-component abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
.dapp-core-ui-component address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.dapp-core-ui-component ol,
.dapp-core-ui-component ul,
.dapp-core-ui-component dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dapp-core-ui-component ol ol,
.dapp-core-ui-component ul ul,
.dapp-core-ui-component ol ul,
.dapp-core-ui-component ul ol {
  margin-bottom: 0;
}
.dapp-core-ui-component dt {
  font-weight: 700;
}
.dapp-core-ui-component dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.dapp-core-ui-component blockquote {
  margin: 0 0 1rem;
}
.dapp-core-ui-component b,
.dapp-core-ui-component strong {
  font-weight: bolder;
}
.dapp-core-ui-component small {
  font-size: 80%;
}
.dapp-core-ui-component sub,
.dapp-core-ui-component sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.dapp-core-ui-component sub {
  bottom: -0.25em;
}
.dapp-core-ui-component sup {
  top: -0.5em;
}
.dapp-core-ui-component a {
  color: #1b46c2;
  text-decoration: none;
  background-color: transparent;
}
.dapp-core-ui-component a:hover {
  color: #122e7f;
  text-decoration: underline;
}
.dapp-core-ui-component a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
.dapp-core-ui-component a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.dapp-core-ui-component pre,
.dapp-core-ui-component code,
.dapp-core-ui-component kbd,
.dapp-core-ui-component samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.dapp-core-ui-component pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
.dapp-core-ui-component figure {
  margin: 0 0 1rem;
}
.dapp-core-ui-component img {
  vertical-align: middle;
  border-style: none;
}
.dapp-core-ui-component svg {
  overflow: hidden;
  vertical-align: middle;
}
.dapp-core-ui-component table {
  border-collapse: collapse;
}
.dapp-core-ui-component caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.dapp-core-ui-component th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.dapp-core-ui-component label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.dapp-core-ui-component button {
  border-radius: 0;
}
.dapp-core-ui-component button:focus:not(:focus-visible) {
  outline: 0;
}
.dapp-core-ui-component input,
.dapp-core-ui-component button,
.dapp-core-ui-component select,
.dapp-core-ui-component optgroup,
.dapp-core-ui-component textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.dapp-core-ui-component button,
.dapp-core-ui-component input {
  overflow: visible;
}
.dapp-core-ui-component button,
.dapp-core-ui-component select {
  text-transform: none;
}
.dapp-core-ui-component [role=button] {
  cursor: pointer;
}
.dapp-core-ui-component select {
  word-wrap: normal;
}
.dapp-core-ui-component button,
.dapp-core-ui-component [type=button],
.dapp-core-ui-component [type=reset],
.dapp-core-ui-component [type=submit] {
  -webkit-appearance: button;
}
.dapp-core-ui-component button:not(:disabled),
.dapp-core-ui-component [type=button]:not(:disabled),
.dapp-core-ui-component [type=reset]:not(:disabled),
.dapp-core-ui-component [type=submit]:not(:disabled) {
  cursor: pointer;
}
.dapp-core-ui-component button::-moz-focus-inner,
.dapp-core-ui-component [type=button]::-moz-focus-inner,
.dapp-core-ui-component [type=reset]::-moz-focus-inner,
.dapp-core-ui-component [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.dapp-core-ui-component input[type=radio],
.dapp-core-ui-component input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.dapp-core-ui-component textarea {
  overflow: auto;
  resize: vertical;
}
.dapp-core-ui-component fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.dapp-core-ui-component legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.dapp-core-ui-component progress {
  vertical-align: baseline;
}
.dapp-core-ui-component [type=number]::-webkit-inner-spin-button,
.dapp-core-ui-component [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.dapp-core-ui-component [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.dapp-core-ui-component [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.dapp-core-ui-component ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.dapp-core-ui-component output {
  display: inline-block;
}
.dapp-core-ui-component summary {
  display: list-item;
  cursor: pointer;
}
.dapp-core-ui-component template {
  display: none;
}
.dapp-core-ui-component [hidden] {
  display: none !important;
}
.dapp-core-ui-component h1, .dapp-core-ui-component h2, .dapp-core-ui-component h3, .dapp-core-ui-component h4, .dapp-core-ui-component h5, .dapp-core-ui-component h6,
.dapp-core-ui-component .h1, .dapp-core-ui-component .h2, .dapp-core-ui-component .h3, .dapp-core-ui-component .h4, .dapp-core-ui-component .h5, .dapp-core-ui-component .h6 {
  margin-bottom: 0.5rem;
  font-weight: 300 !important;
  line-height: 1.2;
}
.dapp-core-ui-component h1, .dapp-core-ui-component .h1 {
  font-size: 2.5rem;
}
.dapp-core-ui-component h2, .dapp-core-ui-component .h2 {
  font-size: 2rem;
}
.dapp-core-ui-component h3, .dapp-core-ui-component .h3 {
  font-size: 1.75rem;
}
.dapp-core-ui-component h4, .dapp-core-ui-component .h4 {
  font-size: 1.5rem;
}
.dapp-core-ui-component h5, .dapp-core-ui-component .h5 {
  font-size: 1.25rem;
}
.dapp-core-ui-component h6, .dapp-core-ui-component .h6 {
  font-size: 1rem;
}
.dapp-core-ui-component .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.dapp-core-ui-component .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.dapp-core-ui-component .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.dapp-core-ui-component .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.dapp-core-ui-component .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.dapp-core-ui-component hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.dapp-core-ui-component small,
.dapp-core-ui-component .small {
  font-size: 80%;
  font-weight: 400;
}
.dapp-core-ui-component mark,
.dapp-core-ui-component .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.dapp-core-ui-component .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.dapp-core-ui-component .list-inline {
  padding-left: 0;
  list-style: none;
}
.dapp-core-ui-component .list-inline-item {
  display: inline-block;
}
.dapp-core-ui-component .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.dapp-core-ui-component .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.dapp-core-ui-component .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.dapp-core-ui-component .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.dapp-core-ui-component .blockquote-footer::before {
  content: "— ";
}
.dapp-core-ui-component .img-fluid {
  max-width: 100%;
  height: auto;
}
.dapp-core-ui-component .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.dapp-core-ui-component .figure {
  display: inline-block;
}
.dapp-core-ui-component .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.dapp-core-ui-component .figure-caption {
  font-size: 90%;
  color: #6c757d;
}
.dapp-core-ui-component code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > .dapp-core-ui-component code {
  color: inherit;
}
.dapp-core-ui-component kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
.dapp-core-ui-component kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
.dapp-core-ui-component pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
.dapp-core-ui-component pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.dapp-core-ui-component .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.dapp-core-ui-component .container,
.dapp-core-ui-component .container-fluid,
.dapp-core-ui-component .container-xl,
.dapp-core-ui-component .container-lg,
.dapp-core-ui-component .container-md,
.dapp-core-ui-component .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .container-sm, .dapp-core-ui-component .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .container-md, .dapp-core-ui-component .container-sm, .dapp-core-ui-component .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .container-lg, .dapp-core-ui-component .container-md, .dapp-core-ui-component .container-sm, .dapp-core-ui-component .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .container-xl, .dapp-core-ui-component .container-lg, .dapp-core-ui-component .container-md, .dapp-core-ui-component .container-sm, .dapp-core-ui-component .container {
    max-width: 1140px;
  }
}
.dapp-core-ui-component .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.dapp-core-ui-component .no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.dapp-core-ui-component .no-gutters > .col,
.dapp-core-ui-component .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.dapp-core-ui-component .col-xl,
.dapp-core-ui-component .col-xl-auto, .dapp-core-ui-component .col-xl-12, .dapp-core-ui-component .col-xl-11, .dapp-core-ui-component .col-xl-10, .dapp-core-ui-component .col-xl-9, .dapp-core-ui-component .col-xl-8, .dapp-core-ui-component .col-xl-7, .dapp-core-ui-component .col-xl-6, .dapp-core-ui-component .col-xl-5, .dapp-core-ui-component .col-xl-4, .dapp-core-ui-component .col-xl-3, .dapp-core-ui-component .col-xl-2, .dapp-core-ui-component .col-xl-1, .dapp-core-ui-component .col-lg,
.dapp-core-ui-component .col-lg-auto, .dapp-core-ui-component .col-lg-12, .dapp-core-ui-component .col-lg-11, .dapp-core-ui-component .col-lg-10, .dapp-core-ui-component .col-lg-9, .dapp-core-ui-component .col-lg-8, .dapp-core-ui-component .col-lg-7, .dapp-core-ui-component .col-lg-6, .dapp-core-ui-component .col-lg-5, .dapp-core-ui-component .col-lg-4, .dapp-core-ui-component .col-lg-3, .dapp-core-ui-component .col-lg-2, .dapp-core-ui-component .col-lg-1, .dapp-core-ui-component .col-md,
.dapp-core-ui-component .col-md-auto, .dapp-core-ui-component .col-md-12, .dapp-core-ui-component .col-md-11, .dapp-core-ui-component .col-md-10, .dapp-core-ui-component .col-md-9, .dapp-core-ui-component .col-md-8, .dapp-core-ui-component .col-md-7, .dapp-core-ui-component .col-md-6, .dapp-core-ui-component .col-md-5, .dapp-core-ui-component .col-md-4, .dapp-core-ui-component .col-md-3, .dapp-core-ui-component .col-md-2, .dapp-core-ui-component .col-md-1, .dapp-core-ui-component .col-sm,
.dapp-core-ui-component .col-sm-auto, .dapp-core-ui-component .col-sm-12, .dapp-core-ui-component .col-sm-11, .dapp-core-ui-component .col-sm-10, .dapp-core-ui-component .col-sm-9, .dapp-core-ui-component .col-sm-8, .dapp-core-ui-component .col-sm-7, .dapp-core-ui-component .col-sm-6, .dapp-core-ui-component .col-sm-5, .dapp-core-ui-component .col-sm-4, .dapp-core-ui-component .col-sm-3, .dapp-core-ui-component .col-sm-2, .dapp-core-ui-component .col-sm-1, .dapp-core-ui-component .col,
.dapp-core-ui-component .col-auto, .dapp-core-ui-component .col-12, .dapp-core-ui-component .col-11, .dapp-core-ui-component .col-10, .dapp-core-ui-component .col-9, .dapp-core-ui-component .col-8, .dapp-core-ui-component .col-7, .dapp-core-ui-component .col-6, .dapp-core-ui-component .col-5, .dapp-core-ui-component .col-4, .dapp-core-ui-component .col-3, .dapp-core-ui-component .col-2, .dapp-core-ui-component .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.dapp-core-ui-component .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.dapp-core-ui-component .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.dapp-core-ui-component .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.dapp-core-ui-component .row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.dapp-core-ui-component .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.dapp-core-ui-component .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.dapp-core-ui-component .row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.dapp-core-ui-component .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.dapp-core-ui-component .col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.dapp-core-ui-component .col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.dapp-core-ui-component .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.dapp-core-ui-component .col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.dapp-core-ui-component .col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.dapp-core-ui-component .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.dapp-core-ui-component .col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.dapp-core-ui-component .col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.dapp-core-ui-component .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.dapp-core-ui-component .col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.dapp-core-ui-component .col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.dapp-core-ui-component .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.dapp-core-ui-component .order-first {
  order: -1;
}
.dapp-core-ui-component .order-last {
  order: 13;
}
.dapp-core-ui-component .order-0 {
  order: 0;
}
.dapp-core-ui-component .order-1 {
  order: 1;
}
.dapp-core-ui-component .order-2 {
  order: 2;
}
.dapp-core-ui-component .order-3 {
  order: 3;
}
.dapp-core-ui-component .order-4 {
  order: 4;
}
.dapp-core-ui-component .order-5 {
  order: 5;
}
.dapp-core-ui-component .order-6 {
  order: 6;
}
.dapp-core-ui-component .order-7 {
  order: 7;
}
.dapp-core-ui-component .order-8 {
  order: 8;
}
.dapp-core-ui-component .order-9 {
  order: 9;
}
.dapp-core-ui-component .order-10 {
  order: 10;
}
.dapp-core-ui-component .order-11 {
  order: 11;
}
.dapp-core-ui-component .order-12 {
  order: 12;
}
.dapp-core-ui-component .offset-1 {
  margin-left: 8.3333333333%;
}
.dapp-core-ui-component .offset-2 {
  margin-left: 16.6666666667%;
}
.dapp-core-ui-component .offset-3 {
  margin-left: 25%;
}
.dapp-core-ui-component .offset-4 {
  margin-left: 33.3333333333%;
}
.dapp-core-ui-component .offset-5 {
  margin-left: 41.6666666667%;
}
.dapp-core-ui-component .offset-6 {
  margin-left: 50%;
}
.dapp-core-ui-component .offset-7 {
  margin-left: 58.3333333333%;
}
.dapp-core-ui-component .offset-8 {
  margin-left: 66.6666666667%;
}
.dapp-core-ui-component .offset-9 {
  margin-left: 75%;
}
.dapp-core-ui-component .offset-10 {
  margin-left: 83.3333333333%;
}
.dapp-core-ui-component .offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dapp-core-ui-component .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .dapp-core-ui-component .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .dapp-core-ui-component .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .dapp-core-ui-component .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .dapp-core-ui-component .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .dapp-core-ui-component .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dapp-core-ui-component .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .dapp-core-ui-component .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .dapp-core-ui-component .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .order-sm-first {
    order: -1;
  }
  .dapp-core-ui-component .order-sm-last {
    order: 13;
  }
  .dapp-core-ui-component .order-sm-0 {
    order: 0;
  }
  .dapp-core-ui-component .order-sm-1 {
    order: 1;
  }
  .dapp-core-ui-component .order-sm-2 {
    order: 2;
  }
  .dapp-core-ui-component .order-sm-3 {
    order: 3;
  }
  .dapp-core-ui-component .order-sm-4 {
    order: 4;
  }
  .dapp-core-ui-component .order-sm-5 {
    order: 5;
  }
  .dapp-core-ui-component .order-sm-6 {
    order: 6;
  }
  .dapp-core-ui-component .order-sm-7 {
    order: 7;
  }
  .dapp-core-ui-component .order-sm-8 {
    order: 8;
  }
  .dapp-core-ui-component .order-sm-9 {
    order: 9;
  }
  .dapp-core-ui-component .order-sm-10 {
    order: 10;
  }
  .dapp-core-ui-component .order-sm-11 {
    order: 11;
  }
  .dapp-core-ui-component .order-sm-12 {
    order: 12;
  }
  .dapp-core-ui-component .offset-sm-0 {
    margin-left: 0;
  }
  .dapp-core-ui-component .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .dapp-core-ui-component .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .dapp-core-ui-component .offset-sm-3 {
    margin-left: 25%;
  }
  .dapp-core-ui-component .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .dapp-core-ui-component .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .dapp-core-ui-component .offset-sm-6 {
    margin-left: 50%;
  }
  .dapp-core-ui-component .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .dapp-core-ui-component .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .dapp-core-ui-component .offset-sm-9 {
    margin-left: 75%;
  }
  .dapp-core-ui-component .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .dapp-core-ui-component .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dapp-core-ui-component .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .dapp-core-ui-component .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .dapp-core-ui-component .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .dapp-core-ui-component .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .dapp-core-ui-component .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .dapp-core-ui-component .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dapp-core-ui-component .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .dapp-core-ui-component .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .dapp-core-ui-component .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .order-md-first {
    order: -1;
  }
  .dapp-core-ui-component .order-md-last {
    order: 13;
  }
  .dapp-core-ui-component .order-md-0 {
    order: 0;
  }
  .dapp-core-ui-component .order-md-1 {
    order: 1;
  }
  .dapp-core-ui-component .order-md-2 {
    order: 2;
  }
  .dapp-core-ui-component .order-md-3 {
    order: 3;
  }
  .dapp-core-ui-component .order-md-4 {
    order: 4;
  }
  .dapp-core-ui-component .order-md-5 {
    order: 5;
  }
  .dapp-core-ui-component .order-md-6 {
    order: 6;
  }
  .dapp-core-ui-component .order-md-7 {
    order: 7;
  }
  .dapp-core-ui-component .order-md-8 {
    order: 8;
  }
  .dapp-core-ui-component .order-md-9 {
    order: 9;
  }
  .dapp-core-ui-component .order-md-10 {
    order: 10;
  }
  .dapp-core-ui-component .order-md-11 {
    order: 11;
  }
  .dapp-core-ui-component .order-md-12 {
    order: 12;
  }
  .dapp-core-ui-component .offset-md-0 {
    margin-left: 0;
  }
  .dapp-core-ui-component .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .dapp-core-ui-component .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .dapp-core-ui-component .offset-md-3 {
    margin-left: 25%;
  }
  .dapp-core-ui-component .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .dapp-core-ui-component .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .dapp-core-ui-component .offset-md-6 {
    margin-left: 50%;
  }
  .dapp-core-ui-component .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .dapp-core-ui-component .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .dapp-core-ui-component .offset-md-9 {
    margin-left: 75%;
  }
  .dapp-core-ui-component .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .dapp-core-ui-component .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dapp-core-ui-component .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .dapp-core-ui-component .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .dapp-core-ui-component .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .dapp-core-ui-component .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .dapp-core-ui-component .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .dapp-core-ui-component .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dapp-core-ui-component .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .dapp-core-ui-component .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .dapp-core-ui-component .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .order-lg-first {
    order: -1;
  }
  .dapp-core-ui-component .order-lg-last {
    order: 13;
  }
  .dapp-core-ui-component .order-lg-0 {
    order: 0;
  }
  .dapp-core-ui-component .order-lg-1 {
    order: 1;
  }
  .dapp-core-ui-component .order-lg-2 {
    order: 2;
  }
  .dapp-core-ui-component .order-lg-3 {
    order: 3;
  }
  .dapp-core-ui-component .order-lg-4 {
    order: 4;
  }
  .dapp-core-ui-component .order-lg-5 {
    order: 5;
  }
  .dapp-core-ui-component .order-lg-6 {
    order: 6;
  }
  .dapp-core-ui-component .order-lg-7 {
    order: 7;
  }
  .dapp-core-ui-component .order-lg-8 {
    order: 8;
  }
  .dapp-core-ui-component .order-lg-9 {
    order: 9;
  }
  .dapp-core-ui-component .order-lg-10 {
    order: 10;
  }
  .dapp-core-ui-component .order-lg-11 {
    order: 11;
  }
  .dapp-core-ui-component .order-lg-12 {
    order: 12;
  }
  .dapp-core-ui-component .offset-lg-0 {
    margin-left: 0;
  }
  .dapp-core-ui-component .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .dapp-core-ui-component .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .dapp-core-ui-component .offset-lg-3 {
    margin-left: 25%;
  }
  .dapp-core-ui-component .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .dapp-core-ui-component .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .dapp-core-ui-component .offset-lg-6 {
    margin-left: 50%;
  }
  .dapp-core-ui-component .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .dapp-core-ui-component .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .dapp-core-ui-component .offset-lg-9 {
    margin-left: 75%;
  }
  .dapp-core-ui-component .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .dapp-core-ui-component .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dapp-core-ui-component .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .dapp-core-ui-component .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .dapp-core-ui-component .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .dapp-core-ui-component .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dapp-core-ui-component .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .dapp-core-ui-component .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .dapp-core-ui-component .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .dapp-core-ui-component .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .dapp-core-ui-component .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .dapp-core-ui-component .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .dapp-core-ui-component .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .dapp-core-ui-component .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .dapp-core-ui-component .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dapp-core-ui-component .order-xl-first {
    order: -1;
  }
  .dapp-core-ui-component .order-xl-last {
    order: 13;
  }
  .dapp-core-ui-component .order-xl-0 {
    order: 0;
  }
  .dapp-core-ui-component .order-xl-1 {
    order: 1;
  }
  .dapp-core-ui-component .order-xl-2 {
    order: 2;
  }
  .dapp-core-ui-component .order-xl-3 {
    order: 3;
  }
  .dapp-core-ui-component .order-xl-4 {
    order: 4;
  }
  .dapp-core-ui-component .order-xl-5 {
    order: 5;
  }
  .dapp-core-ui-component .order-xl-6 {
    order: 6;
  }
  .dapp-core-ui-component .order-xl-7 {
    order: 7;
  }
  .dapp-core-ui-component .order-xl-8 {
    order: 8;
  }
  .dapp-core-ui-component .order-xl-9 {
    order: 9;
  }
  .dapp-core-ui-component .order-xl-10 {
    order: 10;
  }
  .dapp-core-ui-component .order-xl-11 {
    order: 11;
  }
  .dapp-core-ui-component .order-xl-12 {
    order: 12;
  }
  .dapp-core-ui-component .offset-xl-0 {
    margin-left: 0;
  }
  .dapp-core-ui-component .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .dapp-core-ui-component .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .dapp-core-ui-component .offset-xl-3 {
    margin-left: 25%;
  }
  .dapp-core-ui-component .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .dapp-core-ui-component .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .dapp-core-ui-component .offset-xl-6 {
    margin-left: 50%;
  }
  .dapp-core-ui-component .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .dapp-core-ui-component .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .dapp-core-ui-component .offset-xl-9 {
    margin-left: 75%;
  }
  .dapp-core-ui-component .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .dapp-core-ui-component .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.dapp-core-ui-component .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.dapp-core-ui-component .table th,
.dapp-core-ui-component .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.dapp-core-ui-component .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.dapp-core-ui-component .table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.dapp-core-ui-component .table-sm th,
.dapp-core-ui-component .table-sm td {
  padding: 0.3rem;
}
.dapp-core-ui-component .table-bordered {
  border: 1px solid #dee2e6;
}
.dapp-core-ui-component .table-bordered th,
.dapp-core-ui-component .table-bordered td {
  border: 1px solid #dee2e6;
}
.dapp-core-ui-component .table-bordered thead th,
.dapp-core-ui-component .table-bordered thead td {
  border-bottom-width: 2px;
}
.dapp-core-ui-component .table-borderless th,
.dapp-core-ui-component .table-borderless td,
.dapp-core-ui-component .table-borderless thead th,
.dapp-core-ui-component .table-borderless tbody + tbody {
  border: 0;
}
.dapp-core-ui-component .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.dapp-core-ui-component .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.dapp-core-ui-component .table-primary,
.dapp-core-ui-component .table-primary > th,
.dapp-core-ui-component .table-primary > td {
  background-color: #bfcbee;
}
.dapp-core-ui-component .table-primary th,
.dapp-core-ui-component .table-primary td,
.dapp-core-ui-component .table-primary thead th,
.dapp-core-ui-component .table-primary tbody + tbody {
  border-color: #889fdf;
}
.dapp-core-ui-component .table-hover .table-primary:hover {
  background-color: #abbbe9;
}
.dapp-core-ui-component .table-hover .table-primary:hover > td,
.dapp-core-ui-component .table-hover .table-primary:hover > th {
  background-color: #abbbe9;
}
.dapp-core-ui-component .table-secondary,
.dapp-core-ui-component .table-secondary > th,
.dapp-core-ui-component .table-secondary > td {
  background-color: #d6d8db;
}
.dapp-core-ui-component .table-secondary th,
.dapp-core-ui-component .table-secondary td,
.dapp-core-ui-component .table-secondary thead th,
.dapp-core-ui-component .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}
.dapp-core-ui-component .table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.dapp-core-ui-component .table-hover .table-secondary:hover > td,
.dapp-core-ui-component .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.dapp-core-ui-component .table-success,
.dapp-core-ui-component .table-success > th,
.dapp-core-ui-component .table-success > td {
  background-color: #c3e6cb;
}
.dapp-core-ui-component .table-success th,
.dapp-core-ui-component .table-success td,
.dapp-core-ui-component .table-success thead th,
.dapp-core-ui-component .table-success tbody + tbody {
  border-color: #8fd19e;
}
.dapp-core-ui-component .table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.dapp-core-ui-component .table-hover .table-success:hover > td,
.dapp-core-ui-component .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.dapp-core-ui-component .table-info,
.dapp-core-ui-component .table-info > th,
.dapp-core-ui-component .table-info > td {
  background-color: #bee5eb;
}
.dapp-core-ui-component .table-info th,
.dapp-core-ui-component .table-info td,
.dapp-core-ui-component .table-info thead th,
.dapp-core-ui-component .table-info tbody + tbody {
  border-color: #86cfda;
}
.dapp-core-ui-component .table-hover .table-info:hover {
  background-color: #abdde5;
}
.dapp-core-ui-component .table-hover .table-info:hover > td,
.dapp-core-ui-component .table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.dapp-core-ui-component .table-warning,
.dapp-core-ui-component .table-warning > th,
.dapp-core-ui-component .table-warning > td {
  background-color: #ffeeba;
}
.dapp-core-ui-component .table-warning th,
.dapp-core-ui-component .table-warning td,
.dapp-core-ui-component .table-warning thead th,
.dapp-core-ui-component .table-warning tbody + tbody {
  border-color: #ffdf7e;
}
.dapp-core-ui-component .table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.dapp-core-ui-component .table-hover .table-warning:hover > td,
.dapp-core-ui-component .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.dapp-core-ui-component .table-danger,
.dapp-core-ui-component .table-danger > th,
.dapp-core-ui-component .table-danger > td {
  background-color: #f5c6cb;
}
.dapp-core-ui-component .table-danger th,
.dapp-core-ui-component .table-danger td,
.dapp-core-ui-component .table-danger thead th,
.dapp-core-ui-component .table-danger tbody + tbody {
  border-color: #ed969e;
}
.dapp-core-ui-component .table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.dapp-core-ui-component .table-hover .table-danger:hover > td,
.dapp-core-ui-component .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.dapp-core-ui-component .table-light,
.dapp-core-ui-component .table-light > th,
.dapp-core-ui-component .table-light > td {
  background-color: #fefefe;
}
.dapp-core-ui-component .table-light th,
.dapp-core-ui-component .table-light td,
.dapp-core-ui-component .table-light thead th,
.dapp-core-ui-component .table-light tbody + tbody {
  border-color: #fcfcfc;
}
.dapp-core-ui-component .table-hover .table-light:hover {
  background-color: #f1f1f1;
}
.dapp-core-ui-component .table-hover .table-light:hover > td,
.dapp-core-ui-component .table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}
.dapp-core-ui-component .table-dark,
.dapp-core-ui-component .table-dark > th,
.dapp-core-ui-component .table-dark > td {
  background-color: #c6c8ca;
}
.dapp-core-ui-component .table-dark th,
.dapp-core-ui-component .table-dark td,
.dapp-core-ui-component .table-dark thead th,
.dapp-core-ui-component .table-dark tbody + tbody {
  border-color: #95999c;
}
.dapp-core-ui-component .table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.dapp-core-ui-component .table-hover .table-dark:hover > td,
.dapp-core-ui-component .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.dapp-core-ui-component .table-active,
.dapp-core-ui-component .table-active > th,
.dapp-core-ui-component .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.dapp-core-ui-component .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.dapp-core-ui-component .table-hover .table-active:hover > td,
.dapp-core-ui-component .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.dapp-core-ui-component .table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.dapp-core-ui-component .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.dapp-core-ui-component .table-dark {
  color: #fff;
  background-color: #343a40;
}
.dapp-core-ui-component .table-dark th,
.dapp-core-ui-component .table-dark td,
.dapp-core-ui-component .table-dark thead th {
  border-color: #454d55;
}
.dapp-core-ui-component .table-dark.table-bordered {
  border: 0;
}
.dapp-core-ui-component .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.dapp-core-ui-component .table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .dapp-core-ui-component .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .dapp-core-ui-component .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .dapp-core-ui-component .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .dapp-core-ui-component .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .dapp-core-ui-component .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .dapp-core-ui-component .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .dapp-core-ui-component .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .dapp-core-ui-component .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.dapp-core-ui-component .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.dapp-core-ui-component .table-responsive > .table-bordered {
  border: 0;
}
.dapp-core-ui-component .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .form-control {
    transition: none;
  }
}
.dapp-core-ui-component .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.dapp-core-ui-component .form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.dapp-core-ui-component .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7191eb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.dapp-core-ui-component .form-control:disabled, .dapp-core-ui-component .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.dapp-core-ui-component input[type=date].form-control,
.dapp-core-ui-component input[type=time].form-control,
.dapp-core-ui-component input[type=datetime-local].form-control,
.dapp-core-ui-component input[type=month].form-control {
  appearance: none;
}
.dapp-core-ui-component select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.dapp-core-ui-component .form-control-file,
.dapp-core-ui-component .form-control-range {
  display: block;
  width: 100%;
}
.dapp-core-ui-component .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.dapp-core-ui-component .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.dapp-core-ui-component .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.dapp-core-ui-component .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.dapp-core-ui-component .form-control-plaintext.form-control-sm, .dapp-core-ui-component .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.dapp-core-ui-component .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.dapp-core-ui-component .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.dapp-core-ui-component select.form-control[size], .dapp-core-ui-component select.form-control[multiple] {
  height: auto;
}
.dapp-core-ui-component textarea.form-control {
  height: auto;
}
.dapp-core-ui-component .form-group {
  margin-bottom: 1rem;
}
.dapp-core-ui-component .form-text {
  display: block;
  margin-top: 0.25rem;
}
.dapp-core-ui-component .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.dapp-core-ui-component .form-row > .col,
.dapp-core-ui-component .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}
.dapp-core-ui-component .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.dapp-core-ui-component .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.dapp-core-ui-component .form-check-input[disabled] ~ .form-check-label, .dapp-core-ui-component .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.dapp-core-ui-component .form-check-label {
  margin-bottom: 0;
}
.dapp-core-ui-component .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.dapp-core-ui-component .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.dapp-core-ui-component .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.dapp-core-ui-component .valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .dapp-core-ui-component .valid-tooltip, .form-row > [class*=col-] > .dapp-core-ui-component .valid-tooltip {
  left: 5px;
}
.was-validated .dapp-core-ui-component:valid ~ .valid-feedback,
.was-validated .dapp-core-ui-component:valid ~ .valid-tooltip, .dapp-core-ui-component.is-valid ~ .valid-feedback,
.dapp-core-ui-component.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .dapp-core-ui-component .form-control:valid, .dapp-core-ui-component .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .dapp-core-ui-component .form-control:valid:focus, .dapp-core-ui-component .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .dapp-core-ui-component textarea.form-control:valid, .dapp-core-ui-component textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .dapp-core-ui-component .custom-select:valid, .dapp-core-ui-component .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .dapp-core-ui-component .custom-select:valid:focus, .dapp-core-ui-component .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .dapp-core-ui-component .form-check-input:valid ~ .form-check-label, .dapp-core-ui-component .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .dapp-core-ui-component .form-check-input:valid ~ .valid-feedback,
.was-validated .dapp-core-ui-component .form-check-input:valid ~ .valid-tooltip, .dapp-core-ui-component .form-check-input.is-valid ~ .valid-feedback,
.dapp-core-ui-component .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .dapp-core-ui-component .custom-control-input:valid ~ .custom-control-label, .dapp-core-ui-component .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .dapp-core-ui-component .custom-control-input:valid ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .dapp-core-ui-component .custom-control-input:valid:checked ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .dapp-core-ui-component .custom-control-input:valid:focus ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .dapp-core-ui-component .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .dapp-core-ui-component .custom-file-input:valid ~ .custom-file-label, .dapp-core-ui-component .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .dapp-core-ui-component .custom-file-input:valid:focus ~ .custom-file-label, .dapp-core-ui-component .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.dapp-core-ui-component .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.dapp-core-ui-component .invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .dapp-core-ui-component .invalid-tooltip, .form-row > [class*=col-] > .dapp-core-ui-component .invalid-tooltip {
  left: 5px;
}
.was-validated .dapp-core-ui-component:invalid ~ .invalid-feedback,
.was-validated .dapp-core-ui-component:invalid ~ .invalid-tooltip, .dapp-core-ui-component.is-invalid ~ .invalid-feedback,
.dapp-core-ui-component.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .dapp-core-ui-component .form-control:invalid, .dapp-core-ui-component .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .dapp-core-ui-component .form-control:invalid:focus, .dapp-core-ui-component .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .dapp-core-ui-component textarea.form-control:invalid, .dapp-core-ui-component textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated .dapp-core-ui-component .custom-select:invalid, .dapp-core-ui-component .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .dapp-core-ui-component .custom-select:invalid:focus, .dapp-core-ui-component .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .dapp-core-ui-component .form-check-input:invalid ~ .form-check-label, .dapp-core-ui-component .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .dapp-core-ui-component .form-check-input:invalid ~ .invalid-feedback,
.was-validated .dapp-core-ui-component .form-check-input:invalid ~ .invalid-tooltip, .dapp-core-ui-component .form-check-input.is-invalid ~ .invalid-feedback,
.dapp-core-ui-component .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .dapp-core-ui-component .custom-control-input:invalid ~ .custom-control-label, .dapp-core-ui-component .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .dapp-core-ui-component .custom-control-input:invalid ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .dapp-core-ui-component .custom-control-input:invalid:checked ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .dapp-core-ui-component .custom-control-input:invalid:focus ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .dapp-core-ui-component .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .dapp-core-ui-component .custom-file-input:invalid ~ .custom-file-label, .dapp-core-ui-component .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .dapp-core-ui-component .custom-file-input:invalid:focus ~ .custom-file-label, .dapp-core-ui-component .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.dapp-core-ui-component .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.dapp-core-ui-component .form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .dapp-core-ui-component .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .dapp-core-ui-component .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .dapp-core-ui-component .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .dapp-core-ui-component .form-inline .input-group,
.dapp-core-ui-component .form-inline .custom-select {
    width: auto;
  }
  .dapp-core-ui-component .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .dapp-core-ui-component .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .dapp-core-ui-component .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .dapp-core-ui-component .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.dapp-core-ui-component .btn {
  display: inline-block;
  font-weight: 400;
  color: white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .btn {
    transition: none;
  }
}
.dapp-core-ui-component .btn:hover {
  color: #212529;
  text-decoration: none;
}
.dapp-core-ui-component .btn:focus, .dapp-core-ui-component .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .btn.disabled, .dapp-core-ui-component .btn:disabled {
  opacity: 0.65;
}
.dapp-core-ui-component .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.dapp-core-ui-component a.btn.disabled,
.dapp-core-ui-component fieldset:disabled a.btn {
  pointer-events: none;
}
.dapp-core-ui-component .btn-primary {
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .btn-primary:hover {
  color: #fff;
  background-color: #163aa0;
  border-color: #153695;
}
.dapp-core-ui-component .btn-primary:focus, .dapp-core-ui-component .btn-primary.focus {
  color: #fff;
  background-color: #163aa0;
  border-color: #153695;
  box-shadow: 0 0 0 0.2rem rgba(61, 98, 203, 0.5);
}
.dapp-core-ui-component .btn-primary.disabled, .dapp-core-ui-component .btn-primary:disabled {
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #153695;
  border-color: #13328a;
}
.dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 98, 203, 0.5);
}
.dapp-core-ui-component .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.dapp-core-ui-component .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.dapp-core-ui-component .btn-secondary:focus, .dapp-core-ui-component .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.dapp-core-ui-component .btn-secondary.disabled, .dapp-core-ui-component .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.dapp-core-ui-component .btn-secondary:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-secondary:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.dapp-core-ui-component .btn-secondary:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.dapp-core-ui-component .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dapp-core-ui-component .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.dapp-core-ui-component .btn-success:focus, .dapp-core-ui-component .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.dapp-core-ui-component .btn-success.disabled, .dapp-core-ui-component .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dapp-core-ui-component .btn-success:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-success:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.dapp-core-ui-component .btn-success:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-success:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.dapp-core-ui-component .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.dapp-core-ui-component .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.dapp-core-ui-component .btn-info:focus, .dapp-core-ui-component .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.dapp-core-ui-component .btn-info.disabled, .dapp-core-ui-component .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.dapp-core-ui-component .btn-info:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-info:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.dapp-core-ui-component .btn-info:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-info:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.dapp-core-ui-component .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dapp-core-ui-component .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.dapp-core-ui-component .btn-warning:focus, .dapp-core-ui-component .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.dapp-core-ui-component .btn-warning.disabled, .dapp-core-ui-component .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dapp-core-ui-component .btn-warning:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-warning:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.dapp-core-ui-component .btn-warning:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.dapp-core-ui-component .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dapp-core-ui-component .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.dapp-core-ui-component .btn-danger:focus, .dapp-core-ui-component .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.dapp-core-ui-component .btn-danger.disabled, .dapp-core-ui-component .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dapp-core-ui-component .btn-danger:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-danger:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.dapp-core-ui-component .btn-danger:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.dapp-core-ui-component .btn-light {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.dapp-core-ui-component .btn-light:hover {
  color: #212529;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
}
.dapp-core-ui-component .btn-light:focus, .dapp-core-ui-component .btn-light.focus {
  color: #212529;
  background-color: #e7e7e7;
  border-color: #e1e1e1;
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}
.dapp-core-ui-component .btn-light.disabled, .dapp-core-ui-component .btn-light:disabled {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.dapp-core-ui-component .btn-light:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-light:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dadada;
}
.dapp-core-ui-component .btn-light:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-light:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}
.dapp-core-ui-component .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.dapp-core-ui-component .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.dapp-core-ui-component .btn-dark:focus, .dapp-core-ui-component .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.dapp-core-ui-component .btn-dark.disabled, .dapp-core-ui-component .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.dapp-core-ui-component .btn-dark:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-dark:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.dapp-core-ui-component .btn-dark:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.dapp-core-ui-component .btn-outline-primary {
  color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .btn-outline-primary:hover {
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .btn-outline-primary:focus, .dapp-core-ui-component .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .btn-outline-primary.disabled, .dapp-core-ui-component .btn-outline-primary:disabled {
  color: #1b46c2;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-primary:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.dapp-core-ui-component .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.dapp-core-ui-component .btn-outline-secondary:focus, .dapp-core-ui-component .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.dapp-core-ui-component .btn-outline-secondary.disabled, .dapp-core-ui-component .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-secondary:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.dapp-core-ui-component .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.dapp-core-ui-component .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.dapp-core-ui-component .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dapp-core-ui-component .btn-outline-success:focus, .dapp-core-ui-component .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.dapp-core-ui-component .btn-outline-success.disabled, .dapp-core-ui-component .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-success:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-success:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.dapp-core-ui-component .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.dapp-core-ui-component .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.dapp-core-ui-component .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.dapp-core-ui-component .btn-outline-info:focus, .dapp-core-ui-component .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.dapp-core-ui-component .btn-outline-info.disabled, .dapp-core-ui-component .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-info:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-info:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.dapp-core-ui-component .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.dapp-core-ui-component .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.dapp-core-ui-component .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dapp-core-ui-component .btn-outline-warning:focus, .dapp-core-ui-component .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.dapp-core-ui-component .btn-outline-warning.disabled, .dapp-core-ui-component .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-warning:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.dapp-core-ui-component .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.dapp-core-ui-component .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.dapp-core-ui-component .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dapp-core-ui-component .btn-outline-danger:focus, .dapp-core-ui-component .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.dapp-core-ui-component .btn-outline-danger.disabled, .dapp-core-ui-component .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-danger:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.dapp-core-ui-component .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.dapp-core-ui-component .btn-outline-light {
  color: #fafafa;
  border-color: #fafafa;
}
.dapp-core-ui-component .btn-outline-light:hover {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.dapp-core-ui-component .btn-outline-light:focus, .dapp-core-ui-component .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}
.dapp-core-ui-component .btn-outline-light.disabled, .dapp-core-ui-component .btn-outline-light:disabled {
  color: #fafafa;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-light:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-light:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fafafa;
  border-color: #fafafa;
}
.dapp-core-ui-component .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}
.dapp-core-ui-component .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.dapp-core-ui-component .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.dapp-core-ui-component .btn-outline-dark:focus, .dapp-core-ui-component .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.dapp-core-ui-component .btn-outline-dark.disabled, .dapp-core-ui-component .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.dapp-core-ui-component .btn-outline-dark:not(:disabled):not(.disabled):active, .dapp-core-ui-component .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .dapp-core-ui-component .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.dapp-core-ui-component .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dapp-core-ui-component .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .dapp-core-ui-component .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.dapp-core-ui-component .btn-link {
  font-weight: 400;
  color: #1b46c2;
  text-decoration: none;
}
.dapp-core-ui-component .btn-link:hover {
  color: #122e7f;
  text-decoration: underline;
}
.dapp-core-ui-component .btn-link:focus, .dapp-core-ui-component .btn-link.focus {
  text-decoration: underline;
}
.dapp-core-ui-component .btn-link:disabled, .dapp-core-ui-component .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.dapp-core-ui-component .btn-lg, .dapp-core-ui-component .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.dapp-core-ui-component .btn-sm, .dapp-core-ui-component .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.dapp-core-ui-component .btn-block {
  display: block;
  width: 100%;
}
.dapp-core-ui-component .btn-block + .btn-block {
  margin-top: 0.5rem;
}
.dapp-core-ui-component input[type=submit].btn-block,
.dapp-core-ui-component input[type=reset].btn-block,
.dapp-core-ui-component input[type=button].btn-block {
  width: 100%;
}
.dapp-core-ui-component .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .fade {
    transition: none;
  }
}
.dapp-core-ui-component .fade:not(.show) {
  opacity: 0;
}
.dapp-core-ui-component .collapse:not(.show) {
  display: none;
}
.dapp-core-ui-component .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .collapsing {
    transition: none;
  }
}
.dapp-core-ui-component .dropup,
.dapp-core-ui-component .dropright,
.dapp-core-ui-component .dropdown,
.dapp-core-ui-component .dropleft {
  position: relative;
}
.dapp-core-ui-component .dropdown-toggle {
  white-space: nowrap;
}
.dapp-core-ui-component .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dapp-core-ui-component .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dapp-core-ui-component .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dapp-core-ui-component .dropdown-menu-left {
  right: auto;
  left: 0;
}
.dapp-core-ui-component .dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dapp-core-ui-component .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dapp-core-ui-component .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dapp-core-ui-component .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dapp-core-ui-component .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dapp-core-ui-component .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dapp-core-ui-component .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dapp-core-ui-component .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dapp-core-ui-component .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dapp-core-ui-component .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dapp-core-ui-component .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dapp-core-ui-component .dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dapp-core-ui-component .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dapp-core-ui-component .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dapp-core-ui-component .dropleft .dropdown-toggle::after {
  display: none;
}
.dapp-core-ui-component .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dapp-core-ui-component .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dapp-core-ui-component .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dapp-core-ui-component .dropdown-menu[x-placement^=top], .dapp-core-ui-component .dropdown-menu[x-placement^=right], .dapp-core-ui-component .dropdown-menu[x-placement^=bottom], .dapp-core-ui-component .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}
.dapp-core-ui-component .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dapp-core-ui-component .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dapp-core-ui-component .dropdown-item:hover, .dapp-core-ui-component .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dapp-core-ui-component .dropdown-item.active, .dapp-core-ui-component .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1b46c2;
}
.dapp-core-ui-component .dropdown-item.disabled, .dapp-core-ui-component .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}
.dapp-core-ui-component .dropdown-menu.show {
  display: block;
}
.dapp-core-ui-component .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dapp-core-ui-component .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.dapp-core-ui-component .btn-group,
.dapp-core-ui-component .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.dapp-core-ui-component .btn-group > .btn,
.dapp-core-ui-component .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.dapp-core-ui-component .btn-group > .btn:hover,
.dapp-core-ui-component .btn-group-vertical > .btn:hover {
  z-index: 1;
}
.dapp-core-ui-component .btn-group > .btn:focus, .dapp-core-ui-component .btn-group > .btn:active, .dapp-core-ui-component .btn-group > .btn.active,
.dapp-core-ui-component .btn-group-vertical > .btn:focus,
.dapp-core-ui-component .btn-group-vertical > .btn:active,
.dapp-core-ui-component .btn-group-vertical > .btn.active {
  z-index: 1;
}
.dapp-core-ui-component .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dapp-core-ui-component .btn-toolbar .input-group {
  width: auto;
}
.dapp-core-ui-component .btn-group > .btn:not(:first-child),
.dapp-core-ui-component .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.dapp-core-ui-component .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.dapp-core-ui-component .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dapp-core-ui-component .btn-group > .btn:not(:first-child),
.dapp-core-ui-component .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dapp-core-ui-component .dropdown-toggle-split::after, .dropup .dapp-core-ui-component .dropdown-toggle-split::after, .dropright .dapp-core-ui-component .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dapp-core-ui-component .dropdown-toggle-split::before {
  margin-right: 0;
}
.dapp-core-ui-component .btn-sm + .dropdown-toggle-split, .dapp-core-ui-component .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.dapp-core-ui-component .btn-lg + .dropdown-toggle-split, .dapp-core-ui-component .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dapp-core-ui-component .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.dapp-core-ui-component .btn-group-vertical > .btn,
.dapp-core-ui-component .btn-group-vertical > .btn-group {
  width: 100%;
}
.dapp-core-ui-component .btn-group-vertical > .btn:not(:first-child),
.dapp-core-ui-component .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.dapp-core-ui-component .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.dapp-core-ui-component .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .btn-group-vertical > .btn:not(:first-child),
.dapp-core-ui-component .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dapp-core-ui-component .btn-group-toggle > .btn,
.dapp-core-ui-component .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.dapp-core-ui-component .btn-group-toggle > .btn input[type=radio],
.dapp-core-ui-component .btn-group-toggle > .btn input[type=checkbox],
.dapp-core-ui-component .btn-group-toggle > .btn-group > .btn input[type=radio],
.dapp-core-ui-component .btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.dapp-core-ui-component .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.dapp-core-ui-component .input-group > .form-control,
.dapp-core-ui-component .input-group > .form-control-plaintext,
.dapp-core-ui-component .input-group > .custom-select,
.dapp-core-ui-component .input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.dapp-core-ui-component .input-group > .form-control + .form-control,
.dapp-core-ui-component .input-group > .form-control + .custom-select,
.dapp-core-ui-component .input-group > .form-control + .custom-file,
.dapp-core-ui-component .input-group > .form-control-plaintext + .form-control,
.dapp-core-ui-component .input-group > .form-control-plaintext + .custom-select,
.dapp-core-ui-component .input-group > .form-control-plaintext + .custom-file,
.dapp-core-ui-component .input-group > .custom-select + .form-control,
.dapp-core-ui-component .input-group > .custom-select + .custom-select,
.dapp-core-ui-component .input-group > .custom-select + .custom-file,
.dapp-core-ui-component .input-group > .custom-file + .form-control,
.dapp-core-ui-component .input-group > .custom-file + .custom-select,
.dapp-core-ui-component .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.dapp-core-ui-component .input-group > .form-control:focus,
.dapp-core-ui-component .input-group > .custom-select:focus,
.dapp-core-ui-component .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.dapp-core-ui-component .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.dapp-core-ui-component .input-group > .form-control:not(:first-child),
.dapp-core-ui-component .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .input-group > .custom-file {
  display: flex;
  align-items: center;
}
.dapp-core-ui-component .input-group > .custom-file:not(:last-child) .custom-file-label, .dapp-core-ui-component .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .input-group:not(.has-validation) > .form-control:not(:last-child),
.dapp-core-ui-component .input-group:not(.has-validation) > .custom-select:not(:last-child),
.dapp-core-ui-component .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dapp-core-ui-component .input-group.has-validation > .form-control:nth-last-child(n+3),
.dapp-core-ui-component .input-group.has-validation > .custom-select:nth-last-child(n+3),
.dapp-core-ui-component .input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dapp-core-ui-component .input-group-prepend,
.dapp-core-ui-component .input-group-append {
  display: flex;
}
.dapp-core-ui-component .input-group-prepend .btn,
.dapp-core-ui-component .input-group-append .btn {
  position: relative;
  z-index: 2;
}
.dapp-core-ui-component .input-group-prepend .btn:focus,
.dapp-core-ui-component .input-group-append .btn:focus {
  z-index: 3;
}
.dapp-core-ui-component .input-group-prepend .btn + .btn,
.dapp-core-ui-component .input-group-prepend .btn + .input-group-text,
.dapp-core-ui-component .input-group-prepend .input-group-text + .input-group-text,
.dapp-core-ui-component .input-group-prepend .input-group-text + .btn,
.dapp-core-ui-component .input-group-append .btn + .btn,
.dapp-core-ui-component .input-group-append .btn + .input-group-text,
.dapp-core-ui-component .input-group-append .input-group-text + .input-group-text,
.dapp-core-ui-component .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.dapp-core-ui-component .input-group-prepend {
  margin-right: -1px;
}
.dapp-core-ui-component .input-group-append {
  margin-left: -1px;
}
.dapp-core-ui-component .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .input-group-text input[type=radio],
.dapp-core-ui-component .input-group-text input[type=checkbox] {
  margin-top: 0;
}
.dapp-core-ui-component .input-group-lg > .form-control:not(textarea),
.dapp-core-ui-component .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}
.dapp-core-ui-component .input-group-lg > .form-control,
.dapp-core-ui-component .input-group-lg > .custom-select,
.dapp-core-ui-component .input-group-lg > .input-group-prepend > .input-group-text,
.dapp-core-ui-component .input-group-lg > .input-group-append > .input-group-text,
.dapp-core-ui-component .input-group-lg > .input-group-prepend > .btn,
.dapp-core-ui-component .input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.dapp-core-ui-component .input-group-sm > .form-control:not(textarea),
.dapp-core-ui-component .input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}
.dapp-core-ui-component .input-group-sm > .form-control,
.dapp-core-ui-component .input-group-sm > .custom-select,
.dapp-core-ui-component .input-group-sm > .input-group-prepend > .input-group-text,
.dapp-core-ui-component .input-group-sm > .input-group-append > .input-group-text,
.dapp-core-ui-component .input-group-sm > .input-group-prepend > .btn,
.dapp-core-ui-component .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.dapp-core-ui-component .input-group-lg > .custom-select,
.dapp-core-ui-component .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.dapp-core-ui-component .input-group > .input-group-prepend > .btn,
.dapp-core-ui-component .input-group > .input-group-prepend > .input-group-text,
.dapp-core-ui-component .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.dapp-core-ui-component .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.dapp-core-ui-component .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.dapp-core-ui-component .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.dapp-core-ui-component .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.dapp-core-ui-component .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dapp-core-ui-component .input-group > .input-group-append > .btn,
.dapp-core-ui-component .input-group > .input-group-append > .input-group-text,
.dapp-core-ui-component .input-group > .input-group-prepend:not(:first-child) > .btn,
.dapp-core-ui-component .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.dapp-core-ui-component .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.dapp-core-ui-component .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}
.dapp-core-ui-component .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.dapp-core-ui-component .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.dapp-core-ui-component .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1b46c2;
  background-color: #1b46c2;
}
.dapp-core-ui-component .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #7191eb;
}
.dapp-core-ui-component .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #9eb4f2;
  border-color: #9eb4f2;
}
.dapp-core-ui-component .custom-control-input[disabled] ~ .custom-control-label, .dapp-core-ui-component .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.dapp-core-ui-component .custom-control-input[disabled] ~ .custom-control-label::before, .dapp-core-ui-component .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.dapp-core-ui-component .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.dapp-core-ui-component .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.dapp-core-ui-component .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.dapp-core-ui-component .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.dapp-core-ui-component .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1b46c2;
  background-color: #1b46c2;
}
.dapp-core-ui-component .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.dapp-core-ui-component .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .custom-switch {
  padding-left: 2.25rem;
}
.dapp-core-ui-component .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.dapp-core-ui-component .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.dapp-core-ui-component .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.dapp-core-ui-component .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(27, 70, 194, 0.5);
}
.dapp-core-ui-component .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.dapp-core-ui-component .custom-select:focus {
  border-color: #7191eb;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.dapp-core-ui-component .custom-select[multiple], .dapp-core-ui-component .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.dapp-core-ui-component .custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.dapp-core-ui-component .custom-select::-ms-expand {
  display: none;
}
.dapp-core-ui-component .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.dapp-core-ui-component .custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.dapp-core-ui-component .custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.dapp-core-ui-component .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.dapp-core-ui-component .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.dapp-core-ui-component .custom-file-input:focus ~ .custom-file-label {
  border-color: #7191eb;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-file-input[disabled] ~ .custom-file-label, .dapp-core-ui-component .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.dapp-core-ui-component .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.dapp-core-ui-component .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.dapp-core-ui-component .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.dapp-core-ui-component .custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.dapp-core-ui-component .custom-range:focus {
  outline: 0;
}
.dapp-core-ui-component .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .custom-range::-moz-focus-outer {
  border: 0;
}
.dapp-core-ui-component .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1b46c2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.dapp-core-ui-component .custom-range::-webkit-slider-thumb:active {
  background-color: #9eb4f2;
}
.dapp-core-ui-component .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.dapp-core-ui-component .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1b46c2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.dapp-core-ui-component .custom-range::-moz-range-thumb:active {
  background-color: #9eb4f2;
}
.dapp-core-ui-component .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.dapp-core-ui-component .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #1b46c2;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .custom-range::-ms-thumb {
    transition: none;
  }
}
.dapp-core-ui-component .custom-range::-ms-thumb:active {
  background-color: #9eb4f2;
}
.dapp-core-ui-component .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.dapp-core-ui-component .custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.dapp-core-ui-component .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.dapp-core-ui-component .custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.dapp-core-ui-component .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.dapp-core-ui-component .custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.dapp-core-ui-component .custom-range:disabled::-moz-range-track {
  cursor: default;
}
.dapp-core-ui-component .custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.dapp-core-ui-component .custom-control-label::before,
.dapp-core-ui-component .custom-file-label,
.dapp-core-ui-component .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .custom-control-label::before,
.dapp-core-ui-component .custom-file-label,
.dapp-core-ui-component .custom-select {
    transition: none;
  }
}
.dapp-core-ui-component .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.dapp-core-ui-component .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.dapp-core-ui-component .nav-link:hover, .dapp-core-ui-component .nav-link:focus {
  text-decoration: none;
}
.dapp-core-ui-component .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.dapp-core-ui-component .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.dapp-core-ui-component .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.dapp-core-ui-component .nav-tabs .nav-link:hover, .dapp-core-ui-component .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.dapp-core-ui-component .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.dapp-core-ui-component .nav-tabs .nav-link.active,
.dapp-core-ui-component .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.dapp-core-ui-component .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dapp-core-ui-component .nav-pills .nav-link {
  border-radius: 0.25rem;
}
.dapp-core-ui-component .nav-pills .nav-link.active,
.dapp-core-ui-component .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b46c2;
}
.dapp-core-ui-component .nav-fill > .nav-link,
.dapp-core-ui-component .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.dapp-core-ui-component .nav-justified > .nav-link,
.dapp-core-ui-component .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.dapp-core-ui-component .tab-content > .tab-pane {
  display: none;
}
.dapp-core-ui-component .tab-content > .active {
  display: block;
}
.dapp-core-ui-component .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.dapp-core-ui-component .navbar .container,
.dapp-core-ui-component .navbar .container-fluid,
.dapp-core-ui-component .navbar .container-sm,
.dapp-core-ui-component .navbar .container-md,
.dapp-core-ui-component .navbar .container-lg,
.dapp-core-ui-component .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.dapp-core-ui-component .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.dapp-core-ui-component .navbar-brand:hover, .dapp-core-ui-component .navbar-brand:focus {
  text-decoration: none;
}
.dapp-core-ui-component .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.dapp-core-ui-component .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.dapp-core-ui-component .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.dapp-core-ui-component .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.dapp-core-ui-component .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.dapp-core-ui-component .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .navbar-toggler:hover, .dapp-core-ui-component .navbar-toggler:focus {
  text-decoration: none;
}
.dapp-core-ui-component .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}
.dapp-core-ui-component .navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .dapp-core-ui-component .navbar-expand-sm > .container,
.dapp-core-ui-component .navbar-expand-sm > .container-fluid,
.dapp-core-ui-component .navbar-expand-sm > .container-sm,
.dapp-core-ui-component .navbar-expand-sm > .container-md,
.dapp-core-ui-component .navbar-expand-sm > .container-lg,
.dapp-core-ui-component .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .dapp-core-ui-component .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .dapp-core-ui-component .navbar-expand-sm > .container,
.dapp-core-ui-component .navbar-expand-sm > .container-fluid,
.dapp-core-ui-component .navbar-expand-sm > .container-sm,
.dapp-core-ui-component .navbar-expand-sm > .container-md,
.dapp-core-ui-component .navbar-expand-sm > .container-lg,
.dapp-core-ui-component .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dapp-core-ui-component .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .dapp-core-ui-component .navbar-expand-md > .container,
.dapp-core-ui-component .navbar-expand-md > .container-fluid,
.dapp-core-ui-component .navbar-expand-md > .container-sm,
.dapp-core-ui-component .navbar-expand-md > .container-md,
.dapp-core-ui-component .navbar-expand-md > .container-lg,
.dapp-core-ui-component .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .dapp-core-ui-component .navbar-expand-md > .container,
.dapp-core-ui-component .navbar-expand-md > .container-fluid,
.dapp-core-ui-component .navbar-expand-md > .container-sm,
.dapp-core-ui-component .navbar-expand-md > .container-md,
.dapp-core-ui-component .navbar-expand-md > .container-lg,
.dapp-core-ui-component .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dapp-core-ui-component .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .dapp-core-ui-component .navbar-expand-lg > .container,
.dapp-core-ui-component .navbar-expand-lg > .container-fluid,
.dapp-core-ui-component .navbar-expand-lg > .container-sm,
.dapp-core-ui-component .navbar-expand-lg > .container-md,
.dapp-core-ui-component .navbar-expand-lg > .container-lg,
.dapp-core-ui-component .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .dapp-core-ui-component .navbar-expand-lg > .container,
.dapp-core-ui-component .navbar-expand-lg > .container-fluid,
.dapp-core-ui-component .navbar-expand-lg > .container-sm,
.dapp-core-ui-component .navbar-expand-lg > .container-md,
.dapp-core-ui-component .navbar-expand-lg > .container-lg,
.dapp-core-ui-component .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dapp-core-ui-component .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .dapp-core-ui-component .navbar-expand-xl > .container,
.dapp-core-ui-component .navbar-expand-xl > .container-fluid,
.dapp-core-ui-component .navbar-expand-xl > .container-sm,
.dapp-core-ui-component .navbar-expand-xl > .container-md,
.dapp-core-ui-component .navbar-expand-xl > .container-lg,
.dapp-core-ui-component .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .dapp-core-ui-component .navbar-expand-xl > .container,
.dapp-core-ui-component .navbar-expand-xl > .container-fluid,
.dapp-core-ui-component .navbar-expand-xl > .container-sm,
.dapp-core-ui-component .navbar-expand-xl > .container-md,
.dapp-core-ui-component .navbar-expand-xl > .container-lg,
.dapp-core-ui-component .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .dapp-core-ui-component .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.dapp-core-ui-component .navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.dapp-core-ui-component .navbar-expand > .container,
.dapp-core-ui-component .navbar-expand > .container-fluid,
.dapp-core-ui-component .navbar-expand > .container-sm,
.dapp-core-ui-component .navbar-expand > .container-md,
.dapp-core-ui-component .navbar-expand > .container-lg,
.dapp-core-ui-component .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.dapp-core-ui-component .navbar-expand .navbar-nav {
  flex-direction: row;
}
.dapp-core-ui-component .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.dapp-core-ui-component .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.dapp-core-ui-component .navbar-expand > .container,
.dapp-core-ui-component .navbar-expand > .container-fluid,
.dapp-core-ui-component .navbar-expand > .container-sm,
.dapp-core-ui-component .navbar-expand > .container-md,
.dapp-core-ui-component .navbar-expand > .container-lg,
.dapp-core-ui-component .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.dapp-core-ui-component .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.dapp-core-ui-component .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.dapp-core-ui-component .navbar-expand .navbar-toggler {
  display: none;
}
.dapp-core-ui-component .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.dapp-core-ui-component .navbar-light .navbar-brand:hover, .dapp-core-ui-component .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.dapp-core-ui-component .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.dapp-core-ui-component .navbar-light .navbar-nav .nav-link:hover, .dapp-core-ui-component .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.dapp-core-ui-component .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.dapp-core-ui-component .navbar-light .navbar-nav .show > .nav-link,
.dapp-core-ui-component .navbar-light .navbar-nav .active > .nav-link,
.dapp-core-ui-component .navbar-light .navbar-nav .nav-link.show,
.dapp-core-ui-component .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.dapp-core-ui-component .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.dapp-core-ui-component .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.dapp-core-ui-component .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.dapp-core-ui-component .navbar-light .navbar-text a:hover, .dapp-core-ui-component .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.dapp-core-ui-component .navbar-dark .navbar-brand {
  color: #fff;
}
.dapp-core-ui-component .navbar-dark .navbar-brand:hover, .dapp-core-ui-component .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.dapp-core-ui-component .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.dapp-core-ui-component .navbar-dark .navbar-nav .nav-link:hover, .dapp-core-ui-component .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.dapp-core-ui-component .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.dapp-core-ui-component .navbar-dark .navbar-nav .show > .nav-link,
.dapp-core-ui-component .navbar-dark .navbar-nav .active > .nav-link,
.dapp-core-ui-component .navbar-dark .navbar-nav .nav-link.show,
.dapp-core-ui-component .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.dapp-core-ui-component .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.dapp-core-ui-component .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.dapp-core-ui-component .navbar-dark .navbar-text a {
  color: #fff;
}
.dapp-core-ui-component .navbar-dark .navbar-text a:hover, .dapp-core-ui-component .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.dapp-core-ui-component .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.dapp-core-ui-component .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.dapp-core-ui-component .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.dapp-core-ui-component .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .card > .card-header + .list-group,
.dapp-core-ui-component .card > .list-group + .card-footer {
  border-top: 0;
}
.dapp-core-ui-component .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.dapp-core-ui-component .card-title {
  margin-bottom: 0.75rem;
}
.dapp-core-ui-component .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.dapp-core-ui-component .card-text:last-child {
  margin-bottom: 0;
}
.dapp-core-ui-component .card-link:hover {
  text-decoration: none;
}
.dapp-core-ui-component .card-link + .card-link {
  margin-left: 1.25rem;
}
.dapp-core-ui-component .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.dapp-core-ui-component .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.dapp-core-ui-component .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.dapp-core-ui-component .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.dapp-core-ui-component .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.dapp-core-ui-component .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.dapp-core-ui-component .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .card-img,
.dapp-core-ui-component .card-img-top,
.dapp-core-ui-component .card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}
.dapp-core-ui-component .card-img,
.dapp-core-ui-component .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .card-img,
.dapp-core-ui-component .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .dapp-core-ui-component .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.dapp-core-ui-component .card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .dapp-core-ui-component .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .dapp-core-ui-component .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:last-child) .card-img-top,
.dapp-core-ui-component .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:last-child) .card-img-bottom,
.dapp-core-ui-component .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:first-child) .card-img-top,
.dapp-core-ui-component .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .dapp-core-ui-component .card-group > .card:not(:first-child) .card-img-bottom,
.dapp-core-ui-component .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.dapp-core-ui-component .card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .dapp-core-ui-component .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.dapp-core-ui-component .accordion {
  overflow-anchor: none;
}
.dapp-core-ui-component .accordion > .card {
  overflow: hidden;
}
.dapp-core-ui-component .accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dapp-core-ui-component .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.dapp-core-ui-component .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.dapp-core-ui-component .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.dapp-core-ui-component .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.dapp-core-ui-component .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.dapp-core-ui-component .breadcrumb-item.active {
  color: #6c757d;
}
.dapp-core-ui-component .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1b46c2;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.dapp-core-ui-component .page-link:hover {
  z-index: 2;
  color: #122e7f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.dapp-core-ui-component .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.25);
}
.dapp-core-ui-component .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.dapp-core-ui-component .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.dapp-core-ui-component .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.dapp-core-ui-component .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.dapp-core-ui-component .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.dapp-core-ui-component .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.dapp-core-ui-component .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.dapp-core-ui-component .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.dapp-core-ui-component .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.dapp-core-ui-component .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .badge {
    transition: none;
  }
}
a.dapp-core-ui-component .badge:hover, a.dapp-core-ui-component .badge:focus {
  text-decoration: none;
}

.dapp-core-ui-component .badge:empty {
  display: none;
}
.dapp-core-ui-component .btn .badge {
  position: relative;
  top: -1px;
}
.dapp-core-ui-component .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.dapp-core-ui-component .badge-primary {
  color: #fff;
  background-color: #1b46c2;
}
a.dapp-core-ui-component .badge-primary:hover, a.dapp-core-ui-component .badge-primary:focus {
  color: #fff;
  background-color: #153695;
}
a.dapp-core-ui-component .badge-primary:focus, a.dapp-core-ui-component .badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 70, 194, 0.5);
}

.dapp-core-ui-component .badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.dapp-core-ui-component .badge-secondary:hover, a.dapp-core-ui-component .badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.dapp-core-ui-component .badge-secondary:focus, a.dapp-core-ui-component .badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.dapp-core-ui-component .badge-success {
  color: #fff;
  background-color: #28a745;
}
a.dapp-core-ui-component .badge-success:hover, a.dapp-core-ui-component .badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.dapp-core-ui-component .badge-success:focus, a.dapp-core-ui-component .badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.dapp-core-ui-component .badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.dapp-core-ui-component .badge-info:hover, a.dapp-core-ui-component .badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.dapp-core-ui-component .badge-info:focus, a.dapp-core-ui-component .badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.dapp-core-ui-component .badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.dapp-core-ui-component .badge-warning:hover, a.dapp-core-ui-component .badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.dapp-core-ui-component .badge-warning:focus, a.dapp-core-ui-component .badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.dapp-core-ui-component .badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.dapp-core-ui-component .badge-danger:hover, a.dapp-core-ui-component .badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.dapp-core-ui-component .badge-danger:focus, a.dapp-core-ui-component .badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.dapp-core-ui-component .badge-light {
  color: #212529;
  background-color: #fafafa;
}
a.dapp-core-ui-component .badge-light:hover, a.dapp-core-ui-component .badge-light:focus {
  color: #212529;
  background-color: #e1e1e1;
}
a.dapp-core-ui-component .badge-light:focus, a.dapp-core-ui-component .badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5);
}

.dapp-core-ui-component .badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.dapp-core-ui-component .badge-dark:hover, a.dapp-core-ui-component .badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.dapp-core-ui-component .badge-dark:focus, a.dapp-core-ui-component .badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.dapp-core-ui-component .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .jumbotron {
    padding: 4rem 2rem;
  }
}
.dapp-core-ui-component .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.dapp-core-ui-component .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .alert-heading {
  color: inherit;
}
.dapp-core-ui-component .alert-link {
  font-weight: 700;
}
.dapp-core-ui-component .alert-dismissible {
  padding-right: 4rem;
}
.dapp-core-ui-component .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.dapp-core-ui-component .alert-primary {
  color: #0e2465;
  background-color: #d1daf3;
  border-color: #bfcbee;
}
.dapp-core-ui-component .alert-primary hr {
  border-top-color: #abbbe9;
}
.dapp-core-ui-component .alert-primary .alert-link {
  color: #081438;
}
.dapp-core-ui-component .alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.dapp-core-ui-component .alert-secondary hr {
  border-top-color: #c8cbcf;
}
.dapp-core-ui-component .alert-secondary .alert-link {
  color: #202326;
}
.dapp-core-ui-component .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.dapp-core-ui-component .alert-success hr {
  border-top-color: #b1dfbb;
}
.dapp-core-ui-component .alert-success .alert-link {
  color: #0b2e13;
}
.dapp-core-ui-component .alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.dapp-core-ui-component .alert-info hr {
  border-top-color: #abdde5;
}
.dapp-core-ui-component .alert-info .alert-link {
  color: #062c33;
}
.dapp-core-ui-component .alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.dapp-core-ui-component .alert-warning hr {
  border-top-color: #ffe8a1;
}
.dapp-core-ui-component .alert-warning .alert-link {
  color: #533f03;
}
.dapp-core-ui-component .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.dapp-core-ui-component .alert-danger hr {
  border-top-color: #f1b0b7;
}
.dapp-core-ui-component .alert-danger .alert-link {
  color: #491217;
}
.dapp-core-ui-component .alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe;
}
.dapp-core-ui-component .alert-light hr {
  border-top-color: #f1f1f1;
}
.dapp-core-ui-component .alert-light .alert-link {
  color: dimgray;
}
.dapp-core-ui-component .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.dapp-core-ui-component .alert-dark hr {
  border-top-color: #b9bbbe;
}
.dapp-core-ui-component .alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.dapp-core-ui-component .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1b46c2;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .progress-bar {
    transition: none;
  }
}
.dapp-core-ui-component .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.dapp-core-ui-component .progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .progress-bar-animated {
    animation: none;
  }
}
.dapp-core-ui-component .media {
  display: flex;
  align-items: flex-start;
}
.dapp-core-ui-component .media-body {
  flex: 1;
}
.dapp-core-ui-component .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.dapp-core-ui-component .list-group-item-action:hover, .dapp-core-ui-component .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dapp-core-ui-component .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.dapp-core-ui-component .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.dapp-core-ui-component .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.dapp-core-ui-component .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.dapp-core-ui-component .list-group-item.disabled, .dapp-core-ui-component .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.dapp-core-ui-component .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1b46c2;
  border-color: #1b46c2;
}
.dapp-core-ui-component .list-group-item + .dapp-core-ui-component .list-group-item {
  border-top-width: 0;
}
.dapp-core-ui-component .list-group-item + .dapp-core-ui-component .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.dapp-core-ui-component .list-group-horizontal {
  flex-direction: row;
}
.dapp-core-ui-component .list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.dapp-core-ui-component .list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.dapp-core-ui-component .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.dapp-core-ui-component .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.dapp-core-ui-component .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .list-group-horizontal-sm {
    flex-direction: row;
  }
  .dapp-core-ui-component .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .list-group-horizontal-md {
    flex-direction: row;
  }
  .dapp-core-ui-component .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .list-group-horizontal-lg {
    flex-direction: row;
  }
  .dapp-core-ui-component .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .list-group-horizontal-xl {
    flex-direction: row;
  }
  .dapp-core-ui-component .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .dapp-core-ui-component .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.dapp-core-ui-component .list-group-flush {
  border-radius: 0;
}
.dapp-core-ui-component .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.dapp-core-ui-component .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.dapp-core-ui-component .list-group-item-primary {
  color: #0e2465;
  background-color: #bfcbee;
}
.dapp-core-ui-component .list-group-item-primary.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-primary.list-group-item-action:focus {
  color: #0e2465;
  background-color: #abbbe9;
}
.dapp-core-ui-component .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0e2465;
  border-color: #0e2465;
}
.dapp-core-ui-component .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.dapp-core-ui-component .list-group-item-secondary.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.dapp-core-ui-component .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.dapp-core-ui-component .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.dapp-core-ui-component .list-group-item-success.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.dapp-core-ui-component .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.dapp-core-ui-component .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.dapp-core-ui-component .list-group-item-info.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.dapp-core-ui-component .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.dapp-core-ui-component .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.dapp-core-ui-component .list-group-item-warning.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.dapp-core-ui-component .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.dapp-core-ui-component .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.dapp-core-ui-component .list-group-item-danger.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.dapp-core-ui-component .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.dapp-core-ui-component .list-group-item-light {
  color: #828282;
  background-color: #fefefe;
}
.dapp-core-ui-component .list-group-item-light.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-light.list-group-item-action:focus {
  color: #828282;
  background-color: #f1f1f1;
}
.dapp-core-ui-component .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #828282;
  border-color: #828282;
}
.dapp-core-ui-component .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.dapp-core-ui-component .list-group-item-dark.list-group-item-action:hover, .dapp-core-ui-component .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.dapp-core-ui-component .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.dapp-core-ui-component .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.dapp-core-ui-component .close:hover {
  color: #000;
  text-decoration: none;
}
.dapp-core-ui-component .close:not(:disabled):not(.disabled):hover, .dapp-core-ui-component .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
.dapp-core-ui-component button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.dapp-core-ui-component a.close.disabled {
  pointer-events: none;
}
.dapp-core-ui-component .toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.dapp-core-ui-component .toast.showing {
  opacity: 1;
}
.dapp-core-ui-component .toast.show {
  display: block;
  opacity: 1;
}
.dapp-core-ui-component .toast.hide {
  display: none;
}
.dapp-core-ui-component .toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.dapp-core-ui-component .toast-body {
  padding: 0.75rem;
}
.dapp-core-ui-component .modal-open {
  overflow: hidden;
}
.dapp-core-ui-component .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.dapp-core-ui-component .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.dapp-core-ui-component .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .dapp-core-ui-component .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .dapp-core-ui-component .modal-dialog {
    transition: none;
  }
}
.modal.show .dapp-core-ui-component .modal-dialog {
  transform: none;
}
.modal.modal-static .dapp-core-ui-component .modal-dialog {
  transform: scale(1.02);
}
.dapp-core-ui-component .modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.dapp-core-ui-component .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.dapp-core-ui-component .modal-dialog-scrollable .modal-header,
.dapp-core-ui-component .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.dapp-core-ui-component .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.dapp-core-ui-component .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.dapp-core-ui-component .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.dapp-core-ui-component .modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.dapp-core-ui-component .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.dapp-core-ui-component .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.dapp-core-ui-component .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.dapp-core-ui-component .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.dapp-core-ui-component .modal-backdrop.fade {
  opacity: 0;
}
.dapp-core-ui-component .modal-backdrop.show {
  opacity: 0.5;
}
.dapp-core-ui-component .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.dapp-core-ui-component .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.dapp-core-ui-component .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.dapp-core-ui-component .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.dapp-core-ui-component .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.dapp-core-ui-component .modal-footer > * {
  margin: 0.25rem;
}
.dapp-core-ui-component .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .dapp-core-ui-component .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .dapp-core-ui-component .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .dapp-core-ui-component .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .dapp-core-ui-component .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .dapp-core-ui-component .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .modal-lg,
.dapp-core-ui-component .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .modal-xl {
    max-width: 1140px;
  }
}
.dapp-core-ui-component .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.dapp-core-ui-component .tooltip.show {
  opacity: 0.9;
}
.dapp-core-ui-component .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.dapp-core-ui-component .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.dapp-core-ui-component .bs-tooltip-top, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.dapp-core-ui-component .bs-tooltip-top .arrow, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.dapp-core-ui-component .bs-tooltip-top .arrow::before, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.dapp-core-ui-component .bs-tooltip-right, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.dapp-core-ui-component .bs-tooltip-right .arrow, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.dapp-core-ui-component .bs-tooltip-right .arrow::before, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.dapp-core-ui-component .bs-tooltip-bottom, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.dapp-core-ui-component .bs-tooltip-bottom .arrow, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.dapp-core-ui-component .bs-tooltip-bottom .arrow::before, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.dapp-core-ui-component .bs-tooltip-left, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.dapp-core-ui-component .bs-tooltip-left .arrow, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.dapp-core-ui-component .bs-tooltip-left .arrow::before, .dapp-core-ui-component .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.dapp-core-ui-component .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.dapp-core-ui-component .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.dapp-core-ui-component .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.dapp-core-ui-component .popover .arrow::before, .dapp-core-ui-component .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.dapp-core-ui-component .bs-popover-top, .dapp-core-ui-component .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.dapp-core-ui-component .bs-popover-top > .arrow, .dapp-core-ui-component .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.dapp-core-ui-component .bs-popover-top > .arrow::before, .dapp-core-ui-component .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.dapp-core-ui-component .bs-popover-top > .arrow::after, .dapp-core-ui-component .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.dapp-core-ui-component .bs-popover-right, .dapp-core-ui-component .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.dapp-core-ui-component .bs-popover-right > .arrow, .dapp-core-ui-component .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.dapp-core-ui-component .bs-popover-right > .arrow::before, .dapp-core-ui-component .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.dapp-core-ui-component .bs-popover-right > .arrow::after, .dapp-core-ui-component .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.dapp-core-ui-component .bs-popover-bottom, .dapp-core-ui-component .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.dapp-core-ui-component .bs-popover-bottom > .arrow, .dapp-core-ui-component .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.dapp-core-ui-component .bs-popover-bottom > .arrow::before, .dapp-core-ui-component .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.dapp-core-ui-component .bs-popover-bottom > .arrow::after, .dapp-core-ui-component .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.dapp-core-ui-component .bs-popover-bottom .popover-header::before, .dapp-core-ui-component .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.dapp-core-ui-component .bs-popover-left, .dapp-core-ui-component .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.dapp-core-ui-component .bs-popover-left > .arrow, .dapp-core-ui-component .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.dapp-core-ui-component .bs-popover-left > .arrow::before, .dapp-core-ui-component .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.dapp-core-ui-component .bs-popover-left > .arrow::after, .dapp-core-ui-component .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.dapp-core-ui-component .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.dapp-core-ui-component .popover-header:empty {
  display: none;
}
.dapp-core-ui-component .popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.dapp-core-ui-component .carousel {
  position: relative;
}
.dapp-core-ui-component .carousel.pointer-event {
  touch-action: pan-y;
}
.dapp-core-ui-component .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.dapp-core-ui-component .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.dapp-core-ui-component .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .carousel-item {
    transition: none;
  }
}
.dapp-core-ui-component .carousel-item.active,
.dapp-core-ui-component .carousel-item-next,
.dapp-core-ui-component .carousel-item-prev {
  display: block;
}
.dapp-core-ui-component .carousel-item-next:not(.carousel-item-left),
.dapp-core-ui-component .active.carousel-item-right {
  transform: translateX(100%);
}
.dapp-core-ui-component .carousel-item-prev:not(.carousel-item-right),
.dapp-core-ui-component .active.carousel-item-left {
  transform: translateX(-100%);
}
.dapp-core-ui-component .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.dapp-core-ui-component .carousel-fade .carousel-item.active,
.dapp-core-ui-component .carousel-fade .carousel-item-next.carousel-item-left,
.dapp-core-ui-component .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.dapp-core-ui-component .carousel-fade .active.carousel-item-left,
.dapp-core-ui-component .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .carousel-fade .active.carousel-item-left,
.dapp-core-ui-component .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.dapp-core-ui-component .carousel-control-prev,
.dapp-core-ui-component .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .carousel-control-prev,
.dapp-core-ui-component .carousel-control-next {
    transition: none;
  }
}
.dapp-core-ui-component .carousel-control-prev:hover, .dapp-core-ui-component .carousel-control-prev:focus,
.dapp-core-ui-component .carousel-control-next:hover,
.dapp-core-ui-component .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.dapp-core-ui-component .carousel-control-prev {
  left: 0;
}
.dapp-core-ui-component .carousel-control-next {
  right: 0;
}
.dapp-core-ui-component .carousel-control-prev-icon,
.dapp-core-ui-component .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}
.dapp-core-ui-component .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.dapp-core-ui-component .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.dapp-core-ui-component .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .carousel-indicators li {
    transition: none;
  }
}
.dapp-core-ui-component .carousel-indicators .active {
  opacity: 1;
}
.dapp-core-ui-component .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.dapp-core-ui-component .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}
.dapp-core-ui-component .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.dapp-core-ui-component .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}
.dapp-core-ui-component .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .dapp-core-ui-component .spinner-border,
.dapp-core-ui-component .spinner-grow {
    animation-duration: 1.5s;
  }
}
.dapp-core-ui-component .align-baseline {
  vertical-align: baseline !important;
}
.dapp-core-ui-component .align-top {
  vertical-align: top !important;
}
.dapp-core-ui-component .align-middle {
  vertical-align: middle !important;
}
.dapp-core-ui-component .align-bottom {
  vertical-align: bottom !important;
}
.dapp-core-ui-component .align-text-bottom {
  vertical-align: text-bottom !important;
}
.dapp-core-ui-component .align-text-top {
  vertical-align: text-top !important;
}
.dapp-core-ui-component .bg-primary {
  background-color: #1b46c2 !important;
}
.dapp-core-ui-component a.bg-primary:hover, .dapp-core-ui-component a.bg-primary:focus,
.dapp-core-ui-component button.bg-primary:hover,
.dapp-core-ui-component button.bg-primary:focus {
  background-color: #153695 !important;
}
.dapp-core-ui-component .bg-secondary {
  background-color: #6c757d !important;
}
.dapp-core-ui-component a.bg-secondary:hover, .dapp-core-ui-component a.bg-secondary:focus,
.dapp-core-ui-component button.bg-secondary:hover,
.dapp-core-ui-component button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.dapp-core-ui-component .bg-success {
  background-color: #28a745 !important;
}
.dapp-core-ui-component a.bg-success:hover, .dapp-core-ui-component a.bg-success:focus,
.dapp-core-ui-component button.bg-success:hover,
.dapp-core-ui-component button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.dapp-core-ui-component .bg-info {
  background-color: #17a2b8 !important;
}
.dapp-core-ui-component a.bg-info:hover, .dapp-core-ui-component a.bg-info:focus,
.dapp-core-ui-component button.bg-info:hover,
.dapp-core-ui-component button.bg-info:focus {
  background-color: #117a8b !important;
}
.dapp-core-ui-component .bg-warning {
  background-color: #ffc107 !important;
}
.dapp-core-ui-component a.bg-warning:hover, .dapp-core-ui-component a.bg-warning:focus,
.dapp-core-ui-component button.bg-warning:hover,
.dapp-core-ui-component button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.dapp-core-ui-component .bg-danger {
  background-color: #dc3545 !important;
}
.dapp-core-ui-component a.bg-danger:hover, .dapp-core-ui-component a.bg-danger:focus,
.dapp-core-ui-component button.bg-danger:hover,
.dapp-core-ui-component button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.dapp-core-ui-component .bg-light {
  background-color: #fafafa !important;
}
.dapp-core-ui-component a.bg-light:hover, .dapp-core-ui-component a.bg-light:focus,
.dapp-core-ui-component button.bg-light:hover,
.dapp-core-ui-component button.bg-light:focus {
  background-color: #e1e1e1 !important;
}
.dapp-core-ui-component .bg-dark {
  background-color: #343a40 !important;
}
.dapp-core-ui-component a.bg-dark:hover, .dapp-core-ui-component a.bg-dark:focus,
.dapp-core-ui-component button.bg-dark:hover,
.dapp-core-ui-component button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.dapp-core-ui-component .bg-white {
  background-color: #fff !important;
}
.dapp-core-ui-component .bg-transparent {
  background-color: transparent !important;
}
.dapp-core-ui-component .border {
  border: 1px solid #dee2e6 !important;
}
.dapp-core-ui-component .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.dapp-core-ui-component .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.dapp-core-ui-component .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.dapp-core-ui-component .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.dapp-core-ui-component .border-0 {
  border: 0 !important;
}
.dapp-core-ui-component .border-top-0 {
  border-top: 0 !important;
}
.dapp-core-ui-component .border-right-0 {
  border-right: 0 !important;
}
.dapp-core-ui-component .border-bottom-0 {
  border-bottom: 0 !important;
}
.dapp-core-ui-component .border-left-0 {
  border-left: 0 !important;
}
.dapp-core-ui-component .border-primary {
  border-color: #1b46c2 !important;
}
.dapp-core-ui-component .border-secondary {
  border-color: #6c757d !important;
}
.dapp-core-ui-component .border-success {
  border-color: #28a745 !important;
}
.dapp-core-ui-component .border-info {
  border-color: #17a2b8 !important;
}
.dapp-core-ui-component .border-warning {
  border-color: #ffc107 !important;
}
.dapp-core-ui-component .border-danger {
  border-color: #dc3545 !important;
}
.dapp-core-ui-component .border-light {
  border-color: #fafafa !important;
}
.dapp-core-ui-component .border-dark {
  border-color: #343a40 !important;
}
.dapp-core-ui-component .border-white {
  border-color: #fff !important;
}
.dapp-core-ui-component .rounded-sm {
  border-radius: 0.2rem !important;
}
.dapp-core-ui-component .rounded {
  border-radius: 0.25rem !important;
}
.dapp-core-ui-component .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.dapp-core-ui-component .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.dapp-core-ui-component .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.dapp-core-ui-component .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.dapp-core-ui-component .rounded-lg {
  border-radius: 0.3rem !important;
}
.dapp-core-ui-component .rounded-circle {
  border-radius: 50% !important;
}
.dapp-core-ui-component .rounded-pill {
  border-radius: 50rem !important;
}
.dapp-core-ui-component .rounded-0 {
  border-radius: 0 !important;
}
.dapp-core-ui-component .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.dapp-core-ui-component .d-none {
  display: none !important;
}
.dapp-core-ui-component .d-inline {
  display: inline !important;
}
.dapp-core-ui-component .d-inline-block {
  display: inline-block !important;
}
.dapp-core-ui-component .d-block {
  display: block !important;
}
.dapp-core-ui-component .d-table {
  display: table !important;
}
.dapp-core-ui-component .d-table-row {
  display: table-row !important;
}
.dapp-core-ui-component .d-table-cell {
  display: table-cell !important;
}
.dapp-core-ui-component .d-flex {
  display: flex !important;
}
.dapp-core-ui-component .d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .d-sm-none {
    display: none !important;
  }
  .dapp-core-ui-component .d-sm-inline {
    display: inline !important;
  }
  .dapp-core-ui-component .d-sm-inline-block {
    display: inline-block !important;
  }
  .dapp-core-ui-component .d-sm-block {
    display: block !important;
  }
  .dapp-core-ui-component .d-sm-table {
    display: table !important;
  }
  .dapp-core-ui-component .d-sm-table-row {
    display: table-row !important;
  }
  .dapp-core-ui-component .d-sm-table-cell {
    display: table-cell !important;
  }
  .dapp-core-ui-component .d-sm-flex {
    display: flex !important;
  }
  .dapp-core-ui-component .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .d-md-none {
    display: none !important;
  }
  .dapp-core-ui-component .d-md-inline {
    display: inline !important;
  }
  .dapp-core-ui-component .d-md-inline-block {
    display: inline-block !important;
  }
  .dapp-core-ui-component .d-md-block {
    display: block !important;
  }
  .dapp-core-ui-component .d-md-table {
    display: table !important;
  }
  .dapp-core-ui-component .d-md-table-row {
    display: table-row !important;
  }
  .dapp-core-ui-component .d-md-table-cell {
    display: table-cell !important;
  }
  .dapp-core-ui-component .d-md-flex {
    display: flex !important;
  }
  .dapp-core-ui-component .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .d-lg-none {
    display: none !important;
  }
  .dapp-core-ui-component .d-lg-inline {
    display: inline !important;
  }
  .dapp-core-ui-component .d-lg-inline-block {
    display: inline-block !important;
  }
  .dapp-core-ui-component .d-lg-block {
    display: block !important;
  }
  .dapp-core-ui-component .d-lg-table {
    display: table !important;
  }
  .dapp-core-ui-component .d-lg-table-row {
    display: table-row !important;
  }
  .dapp-core-ui-component .d-lg-table-cell {
    display: table-cell !important;
  }
  .dapp-core-ui-component .d-lg-flex {
    display: flex !important;
  }
  .dapp-core-ui-component .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .d-xl-none {
    display: none !important;
  }
  .dapp-core-ui-component .d-xl-inline {
    display: inline !important;
  }
  .dapp-core-ui-component .d-xl-inline-block {
    display: inline-block !important;
  }
  .dapp-core-ui-component .d-xl-block {
    display: block !important;
  }
  .dapp-core-ui-component .d-xl-table {
    display: table !important;
  }
  .dapp-core-ui-component .d-xl-table-row {
    display: table-row !important;
  }
  .dapp-core-ui-component .d-xl-table-cell {
    display: table-cell !important;
  }
  .dapp-core-ui-component .d-xl-flex {
    display: flex !important;
  }
  .dapp-core-ui-component .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .dapp-core-ui-component .d-print-none {
    display: none !important;
  }
  .dapp-core-ui-component .d-print-inline {
    display: inline !important;
  }
  .dapp-core-ui-component .d-print-inline-block {
    display: inline-block !important;
  }
  .dapp-core-ui-component .d-print-block {
    display: block !important;
  }
  .dapp-core-ui-component .d-print-table {
    display: table !important;
  }
  .dapp-core-ui-component .d-print-table-row {
    display: table-row !important;
  }
  .dapp-core-ui-component .d-print-table-cell {
    display: table-cell !important;
  }
  .dapp-core-ui-component .d-print-flex {
    display: flex !important;
  }
  .dapp-core-ui-component .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.dapp-core-ui-component .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.dapp-core-ui-component .embed-responsive::before {
  display: block;
  content: "";
}
.dapp-core-ui-component .embed-responsive .embed-responsive-item,
.dapp-core-ui-component .embed-responsive iframe,
.dapp-core-ui-component .embed-responsive embed,
.dapp-core-ui-component .embed-responsive object,
.dapp-core-ui-component .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.dapp-core-ui-component .embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}
.dapp-core-ui-component .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.dapp-core-ui-component .embed-responsive-4by3::before {
  padding-top: 75%;
}
.dapp-core-ui-component .embed-responsive-1by1::before {
  padding-top: 100%;
}
.dapp-core-ui-component .flex-row {
  flex-direction: row !important;
}
.dapp-core-ui-component .flex-column {
  flex-direction: column !important;
}
.dapp-core-ui-component .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.dapp-core-ui-component .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.dapp-core-ui-component .flex-wrap {
  flex-wrap: wrap !important;
}
.dapp-core-ui-component .flex-nowrap {
  flex-wrap: nowrap !important;
}
.dapp-core-ui-component .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.dapp-core-ui-component .flex-fill {
  flex: 1 1 auto !important;
}
.dapp-core-ui-component .flex-grow-0 {
  flex-grow: 0 !important;
}
.dapp-core-ui-component .flex-grow-1 {
  flex-grow: 1 !important;
}
.dapp-core-ui-component .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.dapp-core-ui-component .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.dapp-core-ui-component .justify-content-start {
  justify-content: flex-start !important;
}
.dapp-core-ui-component .justify-content-end {
  justify-content: flex-end !important;
}
.dapp-core-ui-component .justify-content-center {
  justify-content: center !important;
}
.dapp-core-ui-component .justify-content-between {
  justify-content: space-between !important;
}
.dapp-core-ui-component .justify-content-around {
  justify-content: space-around !important;
}
.dapp-core-ui-component .align-items-start {
  align-items: flex-start !important;
}
.dapp-core-ui-component .align-items-end {
  align-items: flex-end !important;
}
.dapp-core-ui-component .align-items-center {
  align-items: center !important;
}
.dapp-core-ui-component .align-items-baseline {
  align-items: baseline !important;
}
.dapp-core-ui-component .align-items-stretch {
  align-items: stretch !important;
}
.dapp-core-ui-component .align-content-start {
  align-content: flex-start !important;
}
.dapp-core-ui-component .align-content-end {
  align-content: flex-end !important;
}
.dapp-core-ui-component .align-content-center {
  align-content: center !important;
}
.dapp-core-ui-component .align-content-between {
  align-content: space-between !important;
}
.dapp-core-ui-component .align-content-around {
  align-content: space-around !important;
}
.dapp-core-ui-component .align-content-stretch {
  align-content: stretch !important;
}
.dapp-core-ui-component .align-self-auto {
  align-self: auto !important;
}
.dapp-core-ui-component .align-self-start {
  align-self: flex-start !important;
}
.dapp-core-ui-component .align-self-end {
  align-self: flex-end !important;
}
.dapp-core-ui-component .align-self-center {
  align-self: center !important;
}
.dapp-core-ui-component .align-self-baseline {
  align-self: baseline !important;
}
.dapp-core-ui-component .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .flex-sm-row {
    flex-direction: row !important;
  }
  .dapp-core-ui-component .flex-sm-column {
    flex-direction: column !important;
  }
  .dapp-core-ui-component .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dapp-core-ui-component .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dapp-core-ui-component .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .dapp-core-ui-component .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .dapp-core-ui-component .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dapp-core-ui-component .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .dapp-core-ui-component .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .dapp-core-ui-component .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .dapp-core-ui-component .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dapp-core-ui-component .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dapp-core-ui-component .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .dapp-core-ui-component .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .dapp-core-ui-component .justify-content-sm-center {
    justify-content: center !important;
  }
  .dapp-core-ui-component .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .dapp-core-ui-component .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .dapp-core-ui-component .align-items-sm-start {
    align-items: flex-start !important;
  }
  .dapp-core-ui-component .align-items-sm-end {
    align-items: flex-end !important;
  }
  .dapp-core-ui-component .align-items-sm-center {
    align-items: center !important;
  }
  .dapp-core-ui-component .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .dapp-core-ui-component .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .dapp-core-ui-component .align-content-sm-start {
    align-content: flex-start !important;
  }
  .dapp-core-ui-component .align-content-sm-end {
    align-content: flex-end !important;
  }
  .dapp-core-ui-component .align-content-sm-center {
    align-content: center !important;
  }
  .dapp-core-ui-component .align-content-sm-between {
    align-content: space-between !important;
  }
  .dapp-core-ui-component .align-content-sm-around {
    align-content: space-around !important;
  }
  .dapp-core-ui-component .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .dapp-core-ui-component .align-self-sm-auto {
    align-self: auto !important;
  }
  .dapp-core-ui-component .align-self-sm-start {
    align-self: flex-start !important;
  }
  .dapp-core-ui-component .align-self-sm-end {
    align-self: flex-end !important;
  }
  .dapp-core-ui-component .align-self-sm-center {
    align-self: center !important;
  }
  .dapp-core-ui-component .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .dapp-core-ui-component .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .flex-md-row {
    flex-direction: row !important;
  }
  .dapp-core-ui-component .flex-md-column {
    flex-direction: column !important;
  }
  .dapp-core-ui-component .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dapp-core-ui-component .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dapp-core-ui-component .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .dapp-core-ui-component .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .dapp-core-ui-component .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dapp-core-ui-component .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .dapp-core-ui-component .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .dapp-core-ui-component .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .dapp-core-ui-component .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dapp-core-ui-component .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dapp-core-ui-component .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .dapp-core-ui-component .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .dapp-core-ui-component .justify-content-md-center {
    justify-content: center !important;
  }
  .dapp-core-ui-component .justify-content-md-between {
    justify-content: space-between !important;
  }
  .dapp-core-ui-component .justify-content-md-around {
    justify-content: space-around !important;
  }
  .dapp-core-ui-component .align-items-md-start {
    align-items: flex-start !important;
  }
  .dapp-core-ui-component .align-items-md-end {
    align-items: flex-end !important;
  }
  .dapp-core-ui-component .align-items-md-center {
    align-items: center !important;
  }
  .dapp-core-ui-component .align-items-md-baseline {
    align-items: baseline !important;
  }
  .dapp-core-ui-component .align-items-md-stretch {
    align-items: stretch !important;
  }
  .dapp-core-ui-component .align-content-md-start {
    align-content: flex-start !important;
  }
  .dapp-core-ui-component .align-content-md-end {
    align-content: flex-end !important;
  }
  .dapp-core-ui-component .align-content-md-center {
    align-content: center !important;
  }
  .dapp-core-ui-component .align-content-md-between {
    align-content: space-between !important;
  }
  .dapp-core-ui-component .align-content-md-around {
    align-content: space-around !important;
  }
  .dapp-core-ui-component .align-content-md-stretch {
    align-content: stretch !important;
  }
  .dapp-core-ui-component .align-self-md-auto {
    align-self: auto !important;
  }
  .dapp-core-ui-component .align-self-md-start {
    align-self: flex-start !important;
  }
  .dapp-core-ui-component .align-self-md-end {
    align-self: flex-end !important;
  }
  .dapp-core-ui-component .align-self-md-center {
    align-self: center !important;
  }
  .dapp-core-ui-component .align-self-md-baseline {
    align-self: baseline !important;
  }
  .dapp-core-ui-component .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .flex-lg-row {
    flex-direction: row !important;
  }
  .dapp-core-ui-component .flex-lg-column {
    flex-direction: column !important;
  }
  .dapp-core-ui-component .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dapp-core-ui-component .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dapp-core-ui-component .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .dapp-core-ui-component .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .dapp-core-ui-component .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dapp-core-ui-component .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .dapp-core-ui-component .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .dapp-core-ui-component .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .dapp-core-ui-component .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dapp-core-ui-component .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dapp-core-ui-component .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .dapp-core-ui-component .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .dapp-core-ui-component .justify-content-lg-center {
    justify-content: center !important;
  }
  .dapp-core-ui-component .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .dapp-core-ui-component .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .dapp-core-ui-component .align-items-lg-start {
    align-items: flex-start !important;
  }
  .dapp-core-ui-component .align-items-lg-end {
    align-items: flex-end !important;
  }
  .dapp-core-ui-component .align-items-lg-center {
    align-items: center !important;
  }
  .dapp-core-ui-component .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .dapp-core-ui-component .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .dapp-core-ui-component .align-content-lg-start {
    align-content: flex-start !important;
  }
  .dapp-core-ui-component .align-content-lg-end {
    align-content: flex-end !important;
  }
  .dapp-core-ui-component .align-content-lg-center {
    align-content: center !important;
  }
  .dapp-core-ui-component .align-content-lg-between {
    align-content: space-between !important;
  }
  .dapp-core-ui-component .align-content-lg-around {
    align-content: space-around !important;
  }
  .dapp-core-ui-component .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .dapp-core-ui-component .align-self-lg-auto {
    align-self: auto !important;
  }
  .dapp-core-ui-component .align-self-lg-start {
    align-self: flex-start !important;
  }
  .dapp-core-ui-component .align-self-lg-end {
    align-self: flex-end !important;
  }
  .dapp-core-ui-component .align-self-lg-center {
    align-self: center !important;
  }
  .dapp-core-ui-component .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .dapp-core-ui-component .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .flex-xl-row {
    flex-direction: row !important;
  }
  .dapp-core-ui-component .flex-xl-column {
    flex-direction: column !important;
  }
  .dapp-core-ui-component .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .dapp-core-ui-component .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .dapp-core-ui-component .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .dapp-core-ui-component .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .dapp-core-ui-component .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .dapp-core-ui-component .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .dapp-core-ui-component .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .dapp-core-ui-component .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .dapp-core-ui-component .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .dapp-core-ui-component .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .dapp-core-ui-component .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .dapp-core-ui-component .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .dapp-core-ui-component .justify-content-xl-center {
    justify-content: center !important;
  }
  .dapp-core-ui-component .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .dapp-core-ui-component .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .dapp-core-ui-component .align-items-xl-start {
    align-items: flex-start !important;
  }
  .dapp-core-ui-component .align-items-xl-end {
    align-items: flex-end !important;
  }
  .dapp-core-ui-component .align-items-xl-center {
    align-items: center !important;
  }
  .dapp-core-ui-component .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .dapp-core-ui-component .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .dapp-core-ui-component .align-content-xl-start {
    align-content: flex-start !important;
  }
  .dapp-core-ui-component .align-content-xl-end {
    align-content: flex-end !important;
  }
  .dapp-core-ui-component .align-content-xl-center {
    align-content: center !important;
  }
  .dapp-core-ui-component .align-content-xl-between {
    align-content: space-between !important;
  }
  .dapp-core-ui-component .align-content-xl-around {
    align-content: space-around !important;
  }
  .dapp-core-ui-component .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .dapp-core-ui-component .align-self-xl-auto {
    align-self: auto !important;
  }
  .dapp-core-ui-component .align-self-xl-start {
    align-self: flex-start !important;
  }
  .dapp-core-ui-component .align-self-xl-end {
    align-self: flex-end !important;
  }
  .dapp-core-ui-component .align-self-xl-center {
    align-self: center !important;
  }
  .dapp-core-ui-component .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .dapp-core-ui-component .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.dapp-core-ui-component .float-left {
  float: left !important;
}
.dapp-core-ui-component .float-right {
  float: right !important;
}
.dapp-core-ui-component .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .float-sm-left {
    float: left !important;
  }
  .dapp-core-ui-component .float-sm-right {
    float: right !important;
  }
  .dapp-core-ui-component .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .float-md-left {
    float: left !important;
  }
  .dapp-core-ui-component .float-md-right {
    float: right !important;
  }
  .dapp-core-ui-component .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .float-lg-left {
    float: left !important;
  }
  .dapp-core-ui-component .float-lg-right {
    float: right !important;
  }
  .dapp-core-ui-component .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .float-xl-left {
    float: left !important;
  }
  .dapp-core-ui-component .float-xl-right {
    float: right !important;
  }
  .dapp-core-ui-component .float-xl-none {
    float: none !important;
  }
}
.dapp-core-ui-component .user-select-all {
  user-select: all !important;
}
.dapp-core-ui-component .user-select-auto {
  user-select: auto !important;
}
.dapp-core-ui-component .user-select-none {
  user-select: none !important;
}
.dapp-core-ui-component .overflow-auto {
  overflow: auto !important;
}
.dapp-core-ui-component .overflow-hidden {
  overflow: hidden !important;
}
.dapp-core-ui-component .position-static {
  position: static !important;
}
.dapp-core-ui-component .position-relative {
  position: relative !important;
}
.dapp-core-ui-component .position-absolute {
  position: absolute !important;
}
.dapp-core-ui-component .position-fixed {
  position: fixed !important;
}
.dapp-core-ui-component .position-sticky {
  position: sticky !important;
}
.dapp-core-ui-component .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.dapp-core-ui-component .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .dapp-core-ui-component .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.dapp-core-ui-component .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.dapp-core-ui-component .sr-only-focusable:active, .dapp-core-ui-component .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.dapp-core-ui-component .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.dapp-core-ui-component .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dapp-core-ui-component .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.dapp-core-ui-component .shadow-none {
  box-shadow: none !important;
}
.dapp-core-ui-component .w-25 {
  width: 25% !important;
}
.dapp-core-ui-component .w-50 {
  width: 50% !important;
}
.dapp-core-ui-component .w-75 {
  width: 75% !important;
}
.dapp-core-ui-component .w-100 {
  width: 100% !important;
}
.dapp-core-ui-component .w-auto {
  width: auto !important;
}
.dapp-core-ui-component .h-25 {
  height: 25% !important;
}
.dapp-core-ui-component .h-50 {
  height: 50% !important;
}
.dapp-core-ui-component .h-75 {
  height: 75% !important;
}
.dapp-core-ui-component .h-100 {
  height: 100% !important;
}
.dapp-core-ui-component .h-auto {
  height: auto !important;
}
.dapp-core-ui-component .mw-100 {
  max-width: 100% !important;
}
.dapp-core-ui-component .mh-100 {
  max-height: 100% !important;
}
.dapp-core-ui-component .min-vw-100 {
  min-width: 100vw !important;
}
.dapp-core-ui-component .min-vh-100 {
  min-height: 100vh !important;
}
.dapp-core-ui-component .vw-100 {
  width: 100vw !important;
}
.dapp-core-ui-component .vh-100 {
  height: 100vh !important;
}
.dapp-core-ui-component .m-0 {
  margin: 0 !important;
}
.dapp-core-ui-component .mt-0,
.dapp-core-ui-component .my-0 {
  margin-top: 0 !important;
}
.dapp-core-ui-component .mr-0,
.dapp-core-ui-component .mx-0 {
  margin-right: 0 !important;
}
.dapp-core-ui-component .mb-0,
.dapp-core-ui-component .my-0 {
  margin-bottom: 0 !important;
}
.dapp-core-ui-component .ml-0,
.dapp-core-ui-component .mx-0 {
  margin-left: 0 !important;
}
.dapp-core-ui-component .m-1 {
  margin: 0.25rem !important;
}
.dapp-core-ui-component .mt-1,
.dapp-core-ui-component .my-1 {
  margin-top: 0.25rem !important;
}
.dapp-core-ui-component .mr-1,
.dapp-core-ui-component .mx-1 {
  margin-right: 0.25rem !important;
}
.dapp-core-ui-component .mb-1,
.dapp-core-ui-component .my-1 {
  margin-bottom: 0.25rem !important;
}
.dapp-core-ui-component .ml-1,
.dapp-core-ui-component .mx-1 {
  margin-left: 0.25rem !important;
}
.dapp-core-ui-component .m-2 {
  margin: 0.5rem !important;
}
.dapp-core-ui-component .mt-2,
.dapp-core-ui-component .my-2 {
  margin-top: 0.5rem !important;
}
.dapp-core-ui-component .mr-2,
.dapp-core-ui-component .mx-2 {
  margin-right: 0.5rem !important;
}
.dapp-core-ui-component .mb-2,
.dapp-core-ui-component .my-2 {
  margin-bottom: 0.5rem !important;
}
.dapp-core-ui-component .ml-2,
.dapp-core-ui-component .mx-2 {
  margin-left: 0.5rem !important;
}
.dapp-core-ui-component .m-3 {
  margin: 1rem !important;
}
.dapp-core-ui-component .mt-3,
.dapp-core-ui-component .my-3 {
  margin-top: 1rem !important;
}
.dapp-core-ui-component .mr-3,
.dapp-core-ui-component .mx-3 {
  margin-right: 1rem !important;
}
.dapp-core-ui-component .mb-3,
.dapp-core-ui-component .my-3 {
  margin-bottom: 1rem !important;
}
.dapp-core-ui-component .ml-3,
.dapp-core-ui-component .mx-3 {
  margin-left: 1rem !important;
}
.dapp-core-ui-component .m-4 {
  margin: 1.5rem !important;
}
.dapp-core-ui-component .mt-4,
.dapp-core-ui-component .my-4 {
  margin-top: 1.5rem !important;
}
.dapp-core-ui-component .mr-4,
.dapp-core-ui-component .mx-4 {
  margin-right: 1.5rem !important;
}
.dapp-core-ui-component .mb-4,
.dapp-core-ui-component .my-4 {
  margin-bottom: 1.5rem !important;
}
.dapp-core-ui-component .ml-4,
.dapp-core-ui-component .mx-4 {
  margin-left: 1.5rem !important;
}
.dapp-core-ui-component .m-5 {
  margin: 3rem !important;
}
.dapp-core-ui-component .mt-5,
.dapp-core-ui-component .my-5 {
  margin-top: 3rem !important;
}
.dapp-core-ui-component .mr-5,
.dapp-core-ui-component .mx-5 {
  margin-right: 3rem !important;
}
.dapp-core-ui-component .mb-5,
.dapp-core-ui-component .my-5 {
  margin-bottom: 3rem !important;
}
.dapp-core-ui-component .ml-5,
.dapp-core-ui-component .mx-5 {
  margin-left: 3rem !important;
}
.dapp-core-ui-component .p-0 {
  padding: 0 !important;
}
.dapp-core-ui-component .pt-0,
.dapp-core-ui-component .py-0 {
  padding-top: 0 !important;
}
.dapp-core-ui-component .pr-0,
.dapp-core-ui-component .px-0 {
  padding-right: 0 !important;
}
.dapp-core-ui-component .pb-0,
.dapp-core-ui-component .py-0 {
  padding-bottom: 0 !important;
}
.dapp-core-ui-component .pl-0,
.dapp-core-ui-component .px-0 {
  padding-left: 0 !important;
}
.dapp-core-ui-component .p-1 {
  padding: 0.25rem !important;
}
.dapp-core-ui-component .pt-1,
.dapp-core-ui-component .py-1 {
  padding-top: 0.25rem !important;
}
.dapp-core-ui-component .pr-1,
.dapp-core-ui-component .px-1 {
  padding-right: 0.25rem !important;
}
.dapp-core-ui-component .pb-1,
.dapp-core-ui-component .py-1 {
  padding-bottom: 0.25rem !important;
}
.dapp-core-ui-component .pl-1,
.dapp-core-ui-component .px-1 {
  padding-left: 0.25rem !important;
}
.dapp-core-ui-component .p-2 {
  padding: 0.5rem !important;
}
.dapp-core-ui-component .pt-2,
.dapp-core-ui-component .py-2 {
  padding-top: 0.5rem !important;
}
.dapp-core-ui-component .pr-2,
.dapp-core-ui-component .px-2 {
  padding-right: 0.5rem !important;
}
.dapp-core-ui-component .pb-2,
.dapp-core-ui-component .py-2 {
  padding-bottom: 0.5rem !important;
}
.dapp-core-ui-component .pl-2,
.dapp-core-ui-component .px-2 {
  padding-left: 0.5rem !important;
}
.dapp-core-ui-component .p-3 {
  padding: 1rem !important;
}
.dapp-core-ui-component .pt-3,
.dapp-core-ui-component .py-3 {
  padding-top: 1rem !important;
}
.dapp-core-ui-component .pr-3,
.dapp-core-ui-component .px-3 {
  padding-right: 1rem !important;
}
.dapp-core-ui-component .pb-3,
.dapp-core-ui-component .py-3 {
  padding-bottom: 1rem !important;
}
.dapp-core-ui-component .pl-3,
.dapp-core-ui-component .px-3 {
  padding-left: 1rem !important;
}
.dapp-core-ui-component .p-4 {
  padding: 1.5rem !important;
}
.dapp-core-ui-component .pt-4,
.dapp-core-ui-component .py-4 {
  padding-top: 1.5rem !important;
}
.dapp-core-ui-component .pr-4,
.dapp-core-ui-component .px-4 {
  padding-right: 1.5rem !important;
}
.dapp-core-ui-component .pb-4,
.dapp-core-ui-component .py-4 {
  padding-bottom: 1.5rem !important;
}
.dapp-core-ui-component .pl-4,
.dapp-core-ui-component .px-4 {
  padding-left: 1.5rem !important;
}
.dapp-core-ui-component .p-5 {
  padding: 3rem !important;
}
.dapp-core-ui-component .pt-5,
.dapp-core-ui-component .py-5 {
  padding-top: 3rem !important;
}
.dapp-core-ui-component .pr-5,
.dapp-core-ui-component .px-5 {
  padding-right: 3rem !important;
}
.dapp-core-ui-component .pb-5,
.dapp-core-ui-component .py-5 {
  padding-bottom: 3rem !important;
}
.dapp-core-ui-component .pl-5,
.dapp-core-ui-component .px-5 {
  padding-left: 3rem !important;
}
.dapp-core-ui-component .m-n1 {
  margin: -0.25rem !important;
}
.dapp-core-ui-component .mt-n1,
.dapp-core-ui-component .my-n1 {
  margin-top: -0.25rem !important;
}
.dapp-core-ui-component .mr-n1,
.dapp-core-ui-component .mx-n1 {
  margin-right: -0.25rem !important;
}
.dapp-core-ui-component .mb-n1,
.dapp-core-ui-component .my-n1 {
  margin-bottom: -0.25rem !important;
}
.dapp-core-ui-component .ml-n1,
.dapp-core-ui-component .mx-n1 {
  margin-left: -0.25rem !important;
}
.dapp-core-ui-component .m-n2 {
  margin: -0.5rem !important;
}
.dapp-core-ui-component .mt-n2,
.dapp-core-ui-component .my-n2 {
  margin-top: -0.5rem !important;
}
.dapp-core-ui-component .mr-n2,
.dapp-core-ui-component .mx-n2 {
  margin-right: -0.5rem !important;
}
.dapp-core-ui-component .mb-n2,
.dapp-core-ui-component .my-n2 {
  margin-bottom: -0.5rem !important;
}
.dapp-core-ui-component .ml-n2,
.dapp-core-ui-component .mx-n2 {
  margin-left: -0.5rem !important;
}
.dapp-core-ui-component .m-n3 {
  margin: -1rem !important;
}
.dapp-core-ui-component .mt-n3,
.dapp-core-ui-component .my-n3 {
  margin-top: -1rem !important;
}
.dapp-core-ui-component .mr-n3,
.dapp-core-ui-component .mx-n3 {
  margin-right: -1rem !important;
}
.dapp-core-ui-component .mb-n3,
.dapp-core-ui-component .my-n3 {
  margin-bottom: -1rem !important;
}
.dapp-core-ui-component .ml-n3,
.dapp-core-ui-component .mx-n3 {
  margin-left: -1rem !important;
}
.dapp-core-ui-component .m-n4 {
  margin: -1.5rem !important;
}
.dapp-core-ui-component .mt-n4,
.dapp-core-ui-component .my-n4 {
  margin-top: -1.5rem !important;
}
.dapp-core-ui-component .mr-n4,
.dapp-core-ui-component .mx-n4 {
  margin-right: -1.5rem !important;
}
.dapp-core-ui-component .mb-n4,
.dapp-core-ui-component .my-n4 {
  margin-bottom: -1.5rem !important;
}
.dapp-core-ui-component .ml-n4,
.dapp-core-ui-component .mx-n4 {
  margin-left: -1.5rem !important;
}
.dapp-core-ui-component .m-n5 {
  margin: -3rem !important;
}
.dapp-core-ui-component .mt-n5,
.dapp-core-ui-component .my-n5 {
  margin-top: -3rem !important;
}
.dapp-core-ui-component .mr-n5,
.dapp-core-ui-component .mx-n5 {
  margin-right: -3rem !important;
}
.dapp-core-ui-component .mb-n5,
.dapp-core-ui-component .my-n5 {
  margin-bottom: -3rem !important;
}
.dapp-core-ui-component .ml-n5,
.dapp-core-ui-component .mx-n5 {
  margin-left: -3rem !important;
}
.dapp-core-ui-component .m-auto {
  margin: auto !important;
}
.dapp-core-ui-component .mt-auto,
.dapp-core-ui-component .my-auto {
  margin-top: auto !important;
}
.dapp-core-ui-component .mr-auto,
.dapp-core-ui-component .mx-auto {
  margin-right: auto !important;
}
.dapp-core-ui-component .mb-auto,
.dapp-core-ui-component .my-auto {
  margin-bottom: auto !important;
}
.dapp-core-ui-component .ml-auto,
.dapp-core-ui-component .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .m-sm-0 {
    margin: 0 !important;
  }
  .dapp-core-ui-component .mt-sm-0,
.dapp-core-ui-component .my-sm-0 {
    margin-top: 0 !important;
  }
  .dapp-core-ui-component .mr-sm-0,
.dapp-core-ui-component .mx-sm-0 {
    margin-right: 0 !important;
  }
  .dapp-core-ui-component .mb-sm-0,
.dapp-core-ui-component .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .dapp-core-ui-component .ml-sm-0,
.dapp-core-ui-component .mx-sm-0 {
    margin-left: 0 !important;
  }
  .dapp-core-ui-component .m-sm-1 {
    margin: 0.25rem !important;
  }
  .dapp-core-ui-component .mt-sm-1,
.dapp-core-ui-component .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .dapp-core-ui-component .mr-sm-1,
.dapp-core-ui-component .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .dapp-core-ui-component .mb-sm-1,
.dapp-core-ui-component .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .ml-sm-1,
.dapp-core-ui-component .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .dapp-core-ui-component .m-sm-2 {
    margin: 0.5rem !important;
  }
  .dapp-core-ui-component .mt-sm-2,
.dapp-core-ui-component .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .dapp-core-ui-component .mr-sm-2,
.dapp-core-ui-component .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .dapp-core-ui-component .mb-sm-2,
.dapp-core-ui-component .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .ml-sm-2,
.dapp-core-ui-component .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .dapp-core-ui-component .m-sm-3 {
    margin: 1rem !important;
  }
  .dapp-core-ui-component .mt-sm-3,
.dapp-core-ui-component .my-sm-3 {
    margin-top: 1rem !important;
  }
  .dapp-core-ui-component .mr-sm-3,
.dapp-core-ui-component .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .dapp-core-ui-component .mb-sm-3,
.dapp-core-ui-component .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .dapp-core-ui-component .ml-sm-3,
.dapp-core-ui-component .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .dapp-core-ui-component .m-sm-4 {
    margin: 1.5rem !important;
  }
  .dapp-core-ui-component .mt-sm-4,
.dapp-core-ui-component .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .dapp-core-ui-component .mr-sm-4,
.dapp-core-ui-component .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .dapp-core-ui-component .mb-sm-4,
.dapp-core-ui-component .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .ml-sm-4,
.dapp-core-ui-component .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .dapp-core-ui-component .m-sm-5 {
    margin: 3rem !important;
  }
  .dapp-core-ui-component .mt-sm-5,
.dapp-core-ui-component .my-sm-5 {
    margin-top: 3rem !important;
  }
  .dapp-core-ui-component .mr-sm-5,
.dapp-core-ui-component .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .dapp-core-ui-component .mb-sm-5,
.dapp-core-ui-component .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .dapp-core-ui-component .ml-sm-5,
.dapp-core-ui-component .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .dapp-core-ui-component .p-sm-0 {
    padding: 0 !important;
  }
  .dapp-core-ui-component .pt-sm-0,
.dapp-core-ui-component .py-sm-0 {
    padding-top: 0 !important;
  }
  .dapp-core-ui-component .pr-sm-0,
.dapp-core-ui-component .px-sm-0 {
    padding-right: 0 !important;
  }
  .dapp-core-ui-component .pb-sm-0,
.dapp-core-ui-component .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .dapp-core-ui-component .pl-sm-0,
.dapp-core-ui-component .px-sm-0 {
    padding-left: 0 !important;
  }
  .dapp-core-ui-component .p-sm-1 {
    padding: 0.25rem !important;
  }
  .dapp-core-ui-component .pt-sm-1,
.dapp-core-ui-component .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .dapp-core-ui-component .pr-sm-1,
.dapp-core-ui-component .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .dapp-core-ui-component .pb-sm-1,
.dapp-core-ui-component .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .pl-sm-1,
.dapp-core-ui-component .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .dapp-core-ui-component .p-sm-2 {
    padding: 0.5rem !important;
  }
  .dapp-core-ui-component .pt-sm-2,
.dapp-core-ui-component .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .dapp-core-ui-component .pr-sm-2,
.dapp-core-ui-component .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .dapp-core-ui-component .pb-sm-2,
.dapp-core-ui-component .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .pl-sm-2,
.dapp-core-ui-component .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .dapp-core-ui-component .p-sm-3 {
    padding: 1rem !important;
  }
  .dapp-core-ui-component .pt-sm-3,
.dapp-core-ui-component .py-sm-3 {
    padding-top: 1rem !important;
  }
  .dapp-core-ui-component .pr-sm-3,
.dapp-core-ui-component .px-sm-3 {
    padding-right: 1rem !important;
  }
  .dapp-core-ui-component .pb-sm-3,
.dapp-core-ui-component .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .dapp-core-ui-component .pl-sm-3,
.dapp-core-ui-component .px-sm-3 {
    padding-left: 1rem !important;
  }
  .dapp-core-ui-component .p-sm-4 {
    padding: 1.5rem !important;
  }
  .dapp-core-ui-component .pt-sm-4,
.dapp-core-ui-component .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .dapp-core-ui-component .pr-sm-4,
.dapp-core-ui-component .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .dapp-core-ui-component .pb-sm-4,
.dapp-core-ui-component .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .pl-sm-4,
.dapp-core-ui-component .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .dapp-core-ui-component .p-sm-5 {
    padding: 3rem !important;
  }
  .dapp-core-ui-component .pt-sm-5,
.dapp-core-ui-component .py-sm-5 {
    padding-top: 3rem !important;
  }
  .dapp-core-ui-component .pr-sm-5,
.dapp-core-ui-component .px-sm-5 {
    padding-right: 3rem !important;
  }
  .dapp-core-ui-component .pb-sm-5,
.dapp-core-ui-component .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .dapp-core-ui-component .pl-sm-5,
.dapp-core-ui-component .px-sm-5 {
    padding-left: 3rem !important;
  }
  .dapp-core-ui-component .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .dapp-core-ui-component .mt-sm-n1,
.dapp-core-ui-component .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .dapp-core-ui-component .mr-sm-n1,
.dapp-core-ui-component .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .dapp-core-ui-component .mb-sm-n1,
.dapp-core-ui-component .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dapp-core-ui-component .ml-sm-n1,
.dapp-core-ui-component .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .dapp-core-ui-component .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .dapp-core-ui-component .mt-sm-n2,
.dapp-core-ui-component .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .dapp-core-ui-component .mr-sm-n2,
.dapp-core-ui-component .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .dapp-core-ui-component .mb-sm-n2,
.dapp-core-ui-component .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dapp-core-ui-component .ml-sm-n2,
.dapp-core-ui-component .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .dapp-core-ui-component .m-sm-n3 {
    margin: -1rem !important;
  }
  .dapp-core-ui-component .mt-sm-n3,
.dapp-core-ui-component .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .dapp-core-ui-component .mr-sm-n3,
.dapp-core-ui-component .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .dapp-core-ui-component .mb-sm-n3,
.dapp-core-ui-component .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .dapp-core-ui-component .ml-sm-n3,
.dapp-core-ui-component .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .dapp-core-ui-component .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .dapp-core-ui-component .mt-sm-n4,
.dapp-core-ui-component .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .dapp-core-ui-component .mr-sm-n4,
.dapp-core-ui-component .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .dapp-core-ui-component .mb-sm-n4,
.dapp-core-ui-component .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dapp-core-ui-component .ml-sm-n4,
.dapp-core-ui-component .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .dapp-core-ui-component .m-sm-n5 {
    margin: -3rem !important;
  }
  .dapp-core-ui-component .mt-sm-n5,
.dapp-core-ui-component .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .dapp-core-ui-component .mr-sm-n5,
.dapp-core-ui-component .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .dapp-core-ui-component .mb-sm-n5,
.dapp-core-ui-component .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .dapp-core-ui-component .ml-sm-n5,
.dapp-core-ui-component .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .dapp-core-ui-component .m-sm-auto {
    margin: auto !important;
  }
  .dapp-core-ui-component .mt-sm-auto,
.dapp-core-ui-component .my-sm-auto {
    margin-top: auto !important;
  }
  .dapp-core-ui-component .mr-sm-auto,
.dapp-core-ui-component .mx-sm-auto {
    margin-right: auto !important;
  }
  .dapp-core-ui-component .mb-sm-auto,
.dapp-core-ui-component .my-sm-auto {
    margin-bottom: auto !important;
  }
  .dapp-core-ui-component .ml-sm-auto,
.dapp-core-ui-component .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .m-md-0 {
    margin: 0 !important;
  }
  .dapp-core-ui-component .mt-md-0,
.dapp-core-ui-component .my-md-0 {
    margin-top: 0 !important;
  }
  .dapp-core-ui-component .mr-md-0,
.dapp-core-ui-component .mx-md-0 {
    margin-right: 0 !important;
  }
  .dapp-core-ui-component .mb-md-0,
.dapp-core-ui-component .my-md-0 {
    margin-bottom: 0 !important;
  }
  .dapp-core-ui-component .ml-md-0,
.dapp-core-ui-component .mx-md-0 {
    margin-left: 0 !important;
  }
  .dapp-core-ui-component .m-md-1 {
    margin: 0.25rem !important;
  }
  .dapp-core-ui-component .mt-md-1,
.dapp-core-ui-component .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .dapp-core-ui-component .mr-md-1,
.dapp-core-ui-component .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .dapp-core-ui-component .mb-md-1,
.dapp-core-ui-component .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .ml-md-1,
.dapp-core-ui-component .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .dapp-core-ui-component .m-md-2 {
    margin: 0.5rem !important;
  }
  .dapp-core-ui-component .mt-md-2,
.dapp-core-ui-component .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .dapp-core-ui-component .mr-md-2,
.dapp-core-ui-component .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .dapp-core-ui-component .mb-md-2,
.dapp-core-ui-component .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .ml-md-2,
.dapp-core-ui-component .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .dapp-core-ui-component .m-md-3 {
    margin: 1rem !important;
  }
  .dapp-core-ui-component .mt-md-3,
.dapp-core-ui-component .my-md-3 {
    margin-top: 1rem !important;
  }
  .dapp-core-ui-component .mr-md-3,
.dapp-core-ui-component .mx-md-3 {
    margin-right: 1rem !important;
  }
  .dapp-core-ui-component .mb-md-3,
.dapp-core-ui-component .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .dapp-core-ui-component .ml-md-3,
.dapp-core-ui-component .mx-md-3 {
    margin-left: 1rem !important;
  }
  .dapp-core-ui-component .m-md-4 {
    margin: 1.5rem !important;
  }
  .dapp-core-ui-component .mt-md-4,
.dapp-core-ui-component .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .dapp-core-ui-component .mr-md-4,
.dapp-core-ui-component .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .dapp-core-ui-component .mb-md-4,
.dapp-core-ui-component .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .ml-md-4,
.dapp-core-ui-component .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .dapp-core-ui-component .m-md-5 {
    margin: 3rem !important;
  }
  .dapp-core-ui-component .mt-md-5,
.dapp-core-ui-component .my-md-5 {
    margin-top: 3rem !important;
  }
  .dapp-core-ui-component .mr-md-5,
.dapp-core-ui-component .mx-md-5 {
    margin-right: 3rem !important;
  }
  .dapp-core-ui-component .mb-md-5,
.dapp-core-ui-component .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .dapp-core-ui-component .ml-md-5,
.dapp-core-ui-component .mx-md-5 {
    margin-left: 3rem !important;
  }
  .dapp-core-ui-component .p-md-0 {
    padding: 0 !important;
  }
  .dapp-core-ui-component .pt-md-0,
.dapp-core-ui-component .py-md-0 {
    padding-top: 0 !important;
  }
  .dapp-core-ui-component .pr-md-0,
.dapp-core-ui-component .px-md-0 {
    padding-right: 0 !important;
  }
  .dapp-core-ui-component .pb-md-0,
.dapp-core-ui-component .py-md-0 {
    padding-bottom: 0 !important;
  }
  .dapp-core-ui-component .pl-md-0,
.dapp-core-ui-component .px-md-0 {
    padding-left: 0 !important;
  }
  .dapp-core-ui-component .p-md-1 {
    padding: 0.25rem !important;
  }
  .dapp-core-ui-component .pt-md-1,
.dapp-core-ui-component .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .dapp-core-ui-component .pr-md-1,
.dapp-core-ui-component .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .dapp-core-ui-component .pb-md-1,
.dapp-core-ui-component .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .pl-md-1,
.dapp-core-ui-component .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .dapp-core-ui-component .p-md-2 {
    padding: 0.5rem !important;
  }
  .dapp-core-ui-component .pt-md-2,
.dapp-core-ui-component .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .dapp-core-ui-component .pr-md-2,
.dapp-core-ui-component .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .dapp-core-ui-component .pb-md-2,
.dapp-core-ui-component .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .pl-md-2,
.dapp-core-ui-component .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .dapp-core-ui-component .p-md-3 {
    padding: 1rem !important;
  }
  .dapp-core-ui-component .pt-md-3,
.dapp-core-ui-component .py-md-3 {
    padding-top: 1rem !important;
  }
  .dapp-core-ui-component .pr-md-3,
.dapp-core-ui-component .px-md-3 {
    padding-right: 1rem !important;
  }
  .dapp-core-ui-component .pb-md-3,
.dapp-core-ui-component .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .dapp-core-ui-component .pl-md-3,
.dapp-core-ui-component .px-md-3 {
    padding-left: 1rem !important;
  }
  .dapp-core-ui-component .p-md-4 {
    padding: 1.5rem !important;
  }
  .dapp-core-ui-component .pt-md-4,
.dapp-core-ui-component .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .dapp-core-ui-component .pr-md-4,
.dapp-core-ui-component .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .dapp-core-ui-component .pb-md-4,
.dapp-core-ui-component .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .pl-md-4,
.dapp-core-ui-component .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .dapp-core-ui-component .p-md-5 {
    padding: 3rem !important;
  }
  .dapp-core-ui-component .pt-md-5,
.dapp-core-ui-component .py-md-5 {
    padding-top: 3rem !important;
  }
  .dapp-core-ui-component .pr-md-5,
.dapp-core-ui-component .px-md-5 {
    padding-right: 3rem !important;
  }
  .dapp-core-ui-component .pb-md-5,
.dapp-core-ui-component .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .dapp-core-ui-component .pl-md-5,
.dapp-core-ui-component .px-md-5 {
    padding-left: 3rem !important;
  }
  .dapp-core-ui-component .m-md-n1 {
    margin: -0.25rem !important;
  }
  .dapp-core-ui-component .mt-md-n1,
.dapp-core-ui-component .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .dapp-core-ui-component .mr-md-n1,
.dapp-core-ui-component .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .dapp-core-ui-component .mb-md-n1,
.dapp-core-ui-component .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dapp-core-ui-component .ml-md-n1,
.dapp-core-ui-component .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .dapp-core-ui-component .m-md-n2 {
    margin: -0.5rem !important;
  }
  .dapp-core-ui-component .mt-md-n2,
.dapp-core-ui-component .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .dapp-core-ui-component .mr-md-n2,
.dapp-core-ui-component .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .dapp-core-ui-component .mb-md-n2,
.dapp-core-ui-component .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dapp-core-ui-component .ml-md-n2,
.dapp-core-ui-component .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .dapp-core-ui-component .m-md-n3 {
    margin: -1rem !important;
  }
  .dapp-core-ui-component .mt-md-n3,
.dapp-core-ui-component .my-md-n3 {
    margin-top: -1rem !important;
  }
  .dapp-core-ui-component .mr-md-n3,
.dapp-core-ui-component .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .dapp-core-ui-component .mb-md-n3,
.dapp-core-ui-component .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .dapp-core-ui-component .ml-md-n3,
.dapp-core-ui-component .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .dapp-core-ui-component .m-md-n4 {
    margin: -1.5rem !important;
  }
  .dapp-core-ui-component .mt-md-n4,
.dapp-core-ui-component .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .dapp-core-ui-component .mr-md-n4,
.dapp-core-ui-component .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .dapp-core-ui-component .mb-md-n4,
.dapp-core-ui-component .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dapp-core-ui-component .ml-md-n4,
.dapp-core-ui-component .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .dapp-core-ui-component .m-md-n5 {
    margin: -3rem !important;
  }
  .dapp-core-ui-component .mt-md-n5,
.dapp-core-ui-component .my-md-n5 {
    margin-top: -3rem !important;
  }
  .dapp-core-ui-component .mr-md-n5,
.dapp-core-ui-component .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .dapp-core-ui-component .mb-md-n5,
.dapp-core-ui-component .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .dapp-core-ui-component .ml-md-n5,
.dapp-core-ui-component .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .dapp-core-ui-component .m-md-auto {
    margin: auto !important;
  }
  .dapp-core-ui-component .mt-md-auto,
.dapp-core-ui-component .my-md-auto {
    margin-top: auto !important;
  }
  .dapp-core-ui-component .mr-md-auto,
.dapp-core-ui-component .mx-md-auto {
    margin-right: auto !important;
  }
  .dapp-core-ui-component .mb-md-auto,
.dapp-core-ui-component .my-md-auto {
    margin-bottom: auto !important;
  }
  .dapp-core-ui-component .ml-md-auto,
.dapp-core-ui-component .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .m-lg-0 {
    margin: 0 !important;
  }
  .dapp-core-ui-component .mt-lg-0,
.dapp-core-ui-component .my-lg-0 {
    margin-top: 0 !important;
  }
  .dapp-core-ui-component .mr-lg-0,
.dapp-core-ui-component .mx-lg-0 {
    margin-right: 0 !important;
  }
  .dapp-core-ui-component .mb-lg-0,
.dapp-core-ui-component .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .dapp-core-ui-component .ml-lg-0,
.dapp-core-ui-component .mx-lg-0 {
    margin-left: 0 !important;
  }
  .dapp-core-ui-component .m-lg-1 {
    margin: 0.25rem !important;
  }
  .dapp-core-ui-component .mt-lg-1,
.dapp-core-ui-component .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .dapp-core-ui-component .mr-lg-1,
.dapp-core-ui-component .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .dapp-core-ui-component .mb-lg-1,
.dapp-core-ui-component .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .ml-lg-1,
.dapp-core-ui-component .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .dapp-core-ui-component .m-lg-2 {
    margin: 0.5rem !important;
  }
  .dapp-core-ui-component .mt-lg-2,
.dapp-core-ui-component .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .dapp-core-ui-component .mr-lg-2,
.dapp-core-ui-component .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .dapp-core-ui-component .mb-lg-2,
.dapp-core-ui-component .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .ml-lg-2,
.dapp-core-ui-component .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .dapp-core-ui-component .m-lg-3 {
    margin: 1rem !important;
  }
  .dapp-core-ui-component .mt-lg-3,
.dapp-core-ui-component .my-lg-3 {
    margin-top: 1rem !important;
  }
  .dapp-core-ui-component .mr-lg-3,
.dapp-core-ui-component .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .dapp-core-ui-component .mb-lg-3,
.dapp-core-ui-component .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .dapp-core-ui-component .ml-lg-3,
.dapp-core-ui-component .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .dapp-core-ui-component .m-lg-4 {
    margin: 1.5rem !important;
  }
  .dapp-core-ui-component .mt-lg-4,
.dapp-core-ui-component .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .dapp-core-ui-component .mr-lg-4,
.dapp-core-ui-component .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .dapp-core-ui-component .mb-lg-4,
.dapp-core-ui-component .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .ml-lg-4,
.dapp-core-ui-component .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .dapp-core-ui-component .m-lg-5 {
    margin: 3rem !important;
  }
  .dapp-core-ui-component .mt-lg-5,
.dapp-core-ui-component .my-lg-5 {
    margin-top: 3rem !important;
  }
  .dapp-core-ui-component .mr-lg-5,
.dapp-core-ui-component .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .dapp-core-ui-component .mb-lg-5,
.dapp-core-ui-component .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .dapp-core-ui-component .ml-lg-5,
.dapp-core-ui-component .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .dapp-core-ui-component .p-lg-0 {
    padding: 0 !important;
  }
  .dapp-core-ui-component .pt-lg-0,
.dapp-core-ui-component .py-lg-0 {
    padding-top: 0 !important;
  }
  .dapp-core-ui-component .pr-lg-0,
.dapp-core-ui-component .px-lg-0 {
    padding-right: 0 !important;
  }
  .dapp-core-ui-component .pb-lg-0,
.dapp-core-ui-component .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .dapp-core-ui-component .pl-lg-0,
.dapp-core-ui-component .px-lg-0 {
    padding-left: 0 !important;
  }
  .dapp-core-ui-component .p-lg-1 {
    padding: 0.25rem !important;
  }
  .dapp-core-ui-component .pt-lg-1,
.dapp-core-ui-component .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .dapp-core-ui-component .pr-lg-1,
.dapp-core-ui-component .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .dapp-core-ui-component .pb-lg-1,
.dapp-core-ui-component .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .pl-lg-1,
.dapp-core-ui-component .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .dapp-core-ui-component .p-lg-2 {
    padding: 0.5rem !important;
  }
  .dapp-core-ui-component .pt-lg-2,
.dapp-core-ui-component .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .dapp-core-ui-component .pr-lg-2,
.dapp-core-ui-component .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .dapp-core-ui-component .pb-lg-2,
.dapp-core-ui-component .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .pl-lg-2,
.dapp-core-ui-component .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .dapp-core-ui-component .p-lg-3 {
    padding: 1rem !important;
  }
  .dapp-core-ui-component .pt-lg-3,
.dapp-core-ui-component .py-lg-3 {
    padding-top: 1rem !important;
  }
  .dapp-core-ui-component .pr-lg-3,
.dapp-core-ui-component .px-lg-3 {
    padding-right: 1rem !important;
  }
  .dapp-core-ui-component .pb-lg-3,
.dapp-core-ui-component .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .dapp-core-ui-component .pl-lg-3,
.dapp-core-ui-component .px-lg-3 {
    padding-left: 1rem !important;
  }
  .dapp-core-ui-component .p-lg-4 {
    padding: 1.5rem !important;
  }
  .dapp-core-ui-component .pt-lg-4,
.dapp-core-ui-component .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .dapp-core-ui-component .pr-lg-4,
.dapp-core-ui-component .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .dapp-core-ui-component .pb-lg-4,
.dapp-core-ui-component .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .pl-lg-4,
.dapp-core-ui-component .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .dapp-core-ui-component .p-lg-5 {
    padding: 3rem !important;
  }
  .dapp-core-ui-component .pt-lg-5,
.dapp-core-ui-component .py-lg-5 {
    padding-top: 3rem !important;
  }
  .dapp-core-ui-component .pr-lg-5,
.dapp-core-ui-component .px-lg-5 {
    padding-right: 3rem !important;
  }
  .dapp-core-ui-component .pb-lg-5,
.dapp-core-ui-component .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .dapp-core-ui-component .pl-lg-5,
.dapp-core-ui-component .px-lg-5 {
    padding-left: 3rem !important;
  }
  .dapp-core-ui-component .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .dapp-core-ui-component .mt-lg-n1,
.dapp-core-ui-component .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .dapp-core-ui-component .mr-lg-n1,
.dapp-core-ui-component .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .dapp-core-ui-component .mb-lg-n1,
.dapp-core-ui-component .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dapp-core-ui-component .ml-lg-n1,
.dapp-core-ui-component .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .dapp-core-ui-component .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .dapp-core-ui-component .mt-lg-n2,
.dapp-core-ui-component .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .dapp-core-ui-component .mr-lg-n2,
.dapp-core-ui-component .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .dapp-core-ui-component .mb-lg-n2,
.dapp-core-ui-component .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dapp-core-ui-component .ml-lg-n2,
.dapp-core-ui-component .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .dapp-core-ui-component .m-lg-n3 {
    margin: -1rem !important;
  }
  .dapp-core-ui-component .mt-lg-n3,
.dapp-core-ui-component .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .dapp-core-ui-component .mr-lg-n3,
.dapp-core-ui-component .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .dapp-core-ui-component .mb-lg-n3,
.dapp-core-ui-component .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .dapp-core-ui-component .ml-lg-n3,
.dapp-core-ui-component .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .dapp-core-ui-component .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .dapp-core-ui-component .mt-lg-n4,
.dapp-core-ui-component .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .dapp-core-ui-component .mr-lg-n4,
.dapp-core-ui-component .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .dapp-core-ui-component .mb-lg-n4,
.dapp-core-ui-component .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dapp-core-ui-component .ml-lg-n4,
.dapp-core-ui-component .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .dapp-core-ui-component .m-lg-n5 {
    margin: -3rem !important;
  }
  .dapp-core-ui-component .mt-lg-n5,
.dapp-core-ui-component .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .dapp-core-ui-component .mr-lg-n5,
.dapp-core-ui-component .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .dapp-core-ui-component .mb-lg-n5,
.dapp-core-ui-component .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .dapp-core-ui-component .ml-lg-n5,
.dapp-core-ui-component .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .dapp-core-ui-component .m-lg-auto {
    margin: auto !important;
  }
  .dapp-core-ui-component .mt-lg-auto,
.dapp-core-ui-component .my-lg-auto {
    margin-top: auto !important;
  }
  .dapp-core-ui-component .mr-lg-auto,
.dapp-core-ui-component .mx-lg-auto {
    margin-right: auto !important;
  }
  .dapp-core-ui-component .mb-lg-auto,
.dapp-core-ui-component .my-lg-auto {
    margin-bottom: auto !important;
  }
  .dapp-core-ui-component .ml-lg-auto,
.dapp-core-ui-component .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .m-xl-0 {
    margin: 0 !important;
  }
  .dapp-core-ui-component .mt-xl-0,
.dapp-core-ui-component .my-xl-0 {
    margin-top: 0 !important;
  }
  .dapp-core-ui-component .mr-xl-0,
.dapp-core-ui-component .mx-xl-0 {
    margin-right: 0 !important;
  }
  .dapp-core-ui-component .mb-xl-0,
.dapp-core-ui-component .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .dapp-core-ui-component .ml-xl-0,
.dapp-core-ui-component .mx-xl-0 {
    margin-left: 0 !important;
  }
  .dapp-core-ui-component .m-xl-1 {
    margin: 0.25rem !important;
  }
  .dapp-core-ui-component .mt-xl-1,
.dapp-core-ui-component .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .dapp-core-ui-component .mr-xl-1,
.dapp-core-ui-component .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .dapp-core-ui-component .mb-xl-1,
.dapp-core-ui-component .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .ml-xl-1,
.dapp-core-ui-component .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .dapp-core-ui-component .m-xl-2 {
    margin: 0.5rem !important;
  }
  .dapp-core-ui-component .mt-xl-2,
.dapp-core-ui-component .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .dapp-core-ui-component .mr-xl-2,
.dapp-core-ui-component .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .dapp-core-ui-component .mb-xl-2,
.dapp-core-ui-component .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .ml-xl-2,
.dapp-core-ui-component .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .dapp-core-ui-component .m-xl-3 {
    margin: 1rem !important;
  }
  .dapp-core-ui-component .mt-xl-3,
.dapp-core-ui-component .my-xl-3 {
    margin-top: 1rem !important;
  }
  .dapp-core-ui-component .mr-xl-3,
.dapp-core-ui-component .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .dapp-core-ui-component .mb-xl-3,
.dapp-core-ui-component .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .dapp-core-ui-component .ml-xl-3,
.dapp-core-ui-component .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .dapp-core-ui-component .m-xl-4 {
    margin: 1.5rem !important;
  }
  .dapp-core-ui-component .mt-xl-4,
.dapp-core-ui-component .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .dapp-core-ui-component .mr-xl-4,
.dapp-core-ui-component .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .dapp-core-ui-component .mb-xl-4,
.dapp-core-ui-component .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .ml-xl-4,
.dapp-core-ui-component .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .dapp-core-ui-component .m-xl-5 {
    margin: 3rem !important;
  }
  .dapp-core-ui-component .mt-xl-5,
.dapp-core-ui-component .my-xl-5 {
    margin-top: 3rem !important;
  }
  .dapp-core-ui-component .mr-xl-5,
.dapp-core-ui-component .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .dapp-core-ui-component .mb-xl-5,
.dapp-core-ui-component .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .dapp-core-ui-component .ml-xl-5,
.dapp-core-ui-component .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .dapp-core-ui-component .p-xl-0 {
    padding: 0 !important;
  }
  .dapp-core-ui-component .pt-xl-0,
.dapp-core-ui-component .py-xl-0 {
    padding-top: 0 !important;
  }
  .dapp-core-ui-component .pr-xl-0,
.dapp-core-ui-component .px-xl-0 {
    padding-right: 0 !important;
  }
  .dapp-core-ui-component .pb-xl-0,
.dapp-core-ui-component .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .dapp-core-ui-component .pl-xl-0,
.dapp-core-ui-component .px-xl-0 {
    padding-left: 0 !important;
  }
  .dapp-core-ui-component .p-xl-1 {
    padding: 0.25rem !important;
  }
  .dapp-core-ui-component .pt-xl-1,
.dapp-core-ui-component .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .dapp-core-ui-component .pr-xl-1,
.dapp-core-ui-component .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .dapp-core-ui-component .pb-xl-1,
.dapp-core-ui-component .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .dapp-core-ui-component .pl-xl-1,
.dapp-core-ui-component .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .dapp-core-ui-component .p-xl-2 {
    padding: 0.5rem !important;
  }
  .dapp-core-ui-component .pt-xl-2,
.dapp-core-ui-component .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .dapp-core-ui-component .pr-xl-2,
.dapp-core-ui-component .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .dapp-core-ui-component .pb-xl-2,
.dapp-core-ui-component .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .dapp-core-ui-component .pl-xl-2,
.dapp-core-ui-component .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .dapp-core-ui-component .p-xl-3 {
    padding: 1rem !important;
  }
  .dapp-core-ui-component .pt-xl-3,
.dapp-core-ui-component .py-xl-3 {
    padding-top: 1rem !important;
  }
  .dapp-core-ui-component .pr-xl-3,
.dapp-core-ui-component .px-xl-3 {
    padding-right: 1rem !important;
  }
  .dapp-core-ui-component .pb-xl-3,
.dapp-core-ui-component .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .dapp-core-ui-component .pl-xl-3,
.dapp-core-ui-component .px-xl-3 {
    padding-left: 1rem !important;
  }
  .dapp-core-ui-component .p-xl-4 {
    padding: 1.5rem !important;
  }
  .dapp-core-ui-component .pt-xl-4,
.dapp-core-ui-component .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .dapp-core-ui-component .pr-xl-4,
.dapp-core-ui-component .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .dapp-core-ui-component .pb-xl-4,
.dapp-core-ui-component .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .dapp-core-ui-component .pl-xl-4,
.dapp-core-ui-component .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .dapp-core-ui-component .p-xl-5 {
    padding: 3rem !important;
  }
  .dapp-core-ui-component .pt-xl-5,
.dapp-core-ui-component .py-xl-5 {
    padding-top: 3rem !important;
  }
  .dapp-core-ui-component .pr-xl-5,
.dapp-core-ui-component .px-xl-5 {
    padding-right: 3rem !important;
  }
  .dapp-core-ui-component .pb-xl-5,
.dapp-core-ui-component .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .dapp-core-ui-component .pl-xl-5,
.dapp-core-ui-component .px-xl-5 {
    padding-left: 3rem !important;
  }
  .dapp-core-ui-component .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .dapp-core-ui-component .mt-xl-n1,
.dapp-core-ui-component .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .dapp-core-ui-component .mr-xl-n1,
.dapp-core-ui-component .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .dapp-core-ui-component .mb-xl-n1,
.dapp-core-ui-component .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .dapp-core-ui-component .ml-xl-n1,
.dapp-core-ui-component .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .dapp-core-ui-component .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .dapp-core-ui-component .mt-xl-n2,
.dapp-core-ui-component .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .dapp-core-ui-component .mr-xl-n2,
.dapp-core-ui-component .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .dapp-core-ui-component .mb-xl-n2,
.dapp-core-ui-component .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .dapp-core-ui-component .ml-xl-n2,
.dapp-core-ui-component .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .dapp-core-ui-component .m-xl-n3 {
    margin: -1rem !important;
  }
  .dapp-core-ui-component .mt-xl-n3,
.dapp-core-ui-component .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .dapp-core-ui-component .mr-xl-n3,
.dapp-core-ui-component .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .dapp-core-ui-component .mb-xl-n3,
.dapp-core-ui-component .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .dapp-core-ui-component .ml-xl-n3,
.dapp-core-ui-component .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .dapp-core-ui-component .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .dapp-core-ui-component .mt-xl-n4,
.dapp-core-ui-component .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .dapp-core-ui-component .mr-xl-n4,
.dapp-core-ui-component .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .dapp-core-ui-component .mb-xl-n4,
.dapp-core-ui-component .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .dapp-core-ui-component .ml-xl-n4,
.dapp-core-ui-component .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .dapp-core-ui-component .m-xl-n5 {
    margin: -3rem !important;
  }
  .dapp-core-ui-component .mt-xl-n5,
.dapp-core-ui-component .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .dapp-core-ui-component .mr-xl-n5,
.dapp-core-ui-component .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .dapp-core-ui-component .mb-xl-n5,
.dapp-core-ui-component .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .dapp-core-ui-component .ml-xl-n5,
.dapp-core-ui-component .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .dapp-core-ui-component .m-xl-auto {
    margin: auto !important;
  }
  .dapp-core-ui-component .mt-xl-auto,
.dapp-core-ui-component .my-xl-auto {
    margin-top: auto !important;
  }
  .dapp-core-ui-component .mr-xl-auto,
.dapp-core-ui-component .mx-xl-auto {
    margin-right: auto !important;
  }
  .dapp-core-ui-component .mb-xl-auto,
.dapp-core-ui-component .my-xl-auto {
    margin-bottom: auto !important;
  }
  .dapp-core-ui-component .ml-xl-auto,
.dapp-core-ui-component .mx-xl-auto {
    margin-left: auto !important;
  }
}
.dapp-core-ui-component .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.dapp-core-ui-component .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.dapp-core-ui-component .text-justify {
  text-align: justify !important;
}
.dapp-core-ui-component .text-wrap {
  white-space: normal !important;
}
.dapp-core-ui-component .text-nowrap {
  white-space: nowrap !important;
}
.dapp-core-ui-component .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dapp-core-ui-component .text-left {
  text-align: left !important;
}
.dapp-core-ui-component .text-right {
  text-align: right !important;
}
.dapp-core-ui-component .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .text-sm-left {
    text-align: left !important;
  }
  .dapp-core-ui-component .text-sm-right {
    text-align: right !important;
  }
  .dapp-core-ui-component .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .dapp-core-ui-component .text-md-left {
    text-align: left !important;
  }
  .dapp-core-ui-component .text-md-right {
    text-align: right !important;
  }
  .dapp-core-ui-component .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .dapp-core-ui-component .text-lg-left {
    text-align: left !important;
  }
  .dapp-core-ui-component .text-lg-right {
    text-align: right !important;
  }
  .dapp-core-ui-component .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .dapp-core-ui-component .text-xl-left {
    text-align: left !important;
  }
  .dapp-core-ui-component .text-xl-right {
    text-align: right !important;
  }
  .dapp-core-ui-component .text-xl-center {
    text-align: center !important;
  }
}
.dapp-core-ui-component .text-lowercase {
  text-transform: lowercase !important;
}
.dapp-core-ui-component .text-uppercase {
  text-transform: uppercase !important;
}
.dapp-core-ui-component .text-capitalize {
  text-transform: capitalize !important;
}
.dapp-core-ui-component .font-weight-light {
  font-weight: 300 !important;
}
.dapp-core-ui-component .font-weight-lighter {
  font-weight: lighter !important;
}
.dapp-core-ui-component .font-weight-normal {
  font-weight: 400 !important;
}
.dapp-core-ui-component .font-weight-bold {
  font-weight: 700 !important;
}
.dapp-core-ui-component .font-weight-bolder {
  font-weight: bolder !important;
}
.dapp-core-ui-component .font-italic {
  font-style: italic !important;
}
.dapp-core-ui-component .text-white {
  color: #fff !important;
}
.dapp-core-ui-component .text-primary {
  color: #1b46c2 !important;
}
.dapp-core-ui-component a.text-primary:hover, .dapp-core-ui-component a.text-primary:focus {
  color: #122e7f !important;
}
.dapp-core-ui-component .text-secondary {
  color: #6c757d !important;
}
.dapp-core-ui-component a.text-secondary:hover, .dapp-core-ui-component a.text-secondary:focus {
  color: #494f54 !important;
}
.dapp-core-ui-component .text-success {
  color: #28a745 !important;
}
.dapp-core-ui-component a.text-success:hover, .dapp-core-ui-component a.text-success:focus {
  color: #19692c !important;
}
.dapp-core-ui-component .text-info {
  color: #17a2b8 !important;
}
.dapp-core-ui-component a.text-info:hover, .dapp-core-ui-component a.text-info:focus {
  color: #0f6674 !important;
}
.dapp-core-ui-component .text-warning {
  color: #ffc107 !important;
}
.dapp-core-ui-component a.text-warning:hover, .dapp-core-ui-component a.text-warning:focus {
  color: #ba8b00 !important;
}
.dapp-core-ui-component .text-danger {
  color: #dc3545 !important;
}
.dapp-core-ui-component a.text-danger:hover, .dapp-core-ui-component a.text-danger:focus {
  color: #a71d2a !important;
}
.dapp-core-ui-component .text-light {
  color: #fafafa !important;
}
.dapp-core-ui-component a.text-light:hover, .dapp-core-ui-component a.text-light:focus {
  color: #d4d4d4 !important;
}
.dapp-core-ui-component .text-dark {
  color: #343a40 !important;
}
.dapp-core-ui-component a.text-dark:hover, .dapp-core-ui-component a.text-dark:focus {
  color: #121416 !important;
}
.dapp-core-ui-component .text-body {
  color: #212529 !important;
}
.dapp-core-ui-component .text-muted {
  color: #6c757d !important;
}
.dapp-core-ui-component .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.dapp-core-ui-component .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.dapp-core-ui-component .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.dapp-core-ui-component .text-decoration-none {
  text-decoration: none !important;
}
.dapp-core-ui-component .text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.dapp-core-ui-component .text-reset {
  color: inherit !important;
}
.dapp-core-ui-component .visible {
  visibility: visible !important;
}
.dapp-core-ui-component .invisible {
  visibility: hidden !important;
}
@media print {
  .dapp-core-ui-component *,
.dapp-core-ui-component *::before,
.dapp-core-ui-component *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .dapp-core-ui-component a:not(.btn) {
    text-decoration: underline;
  }
  .dapp-core-ui-component abbr[title]::after {
    content: " (" attr(title) ")";
  }
  .dapp-core-ui-component pre {
    white-space: pre-wrap !important;
  }
  .dapp-core-ui-component pre,
.dapp-core-ui-component blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  .dapp-core-ui-component thead {
    display: table-header-group;
  }
  .dapp-core-ui-component tr,
.dapp-core-ui-component img {
    page-break-inside: avoid;
  }
  .dapp-core-ui-component p,
.dapp-core-ui-component h2,
.dapp-core-ui-component h3 {
    orphans: 3;
    widows: 3;
  }
  .dapp-core-ui-component h2,
.dapp-core-ui-component h3 {
    page-break-after: avoid;
  }
  @page {
    .dapp-core-ui-component {
      size: a3;
    }
  }
  .dapp-core-ui-component body {
    min-width: 992px !important;
  }
  .dapp-core-ui-component .container {
    min-width: 992px !important;
  }
  .dapp-core-ui-component .navbar {
    display: none;
  }
  .dapp-core-ui-component .badge {
    border: 1px solid #000;
  }
  .dapp-core-ui-component .table {
    border-collapse: collapse !important;
  }
  .dapp-core-ui-component .table td,
.dapp-core-ui-component .table th {
    background-color: #fff !important;
  }
  .dapp-core-ui-component .table-bordered th,
.dapp-core-ui-component .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .dapp-core-ui-component .table-dark {
    color: inherit;
  }
  .dapp-core-ui-component .table-dark th,
.dapp-core-ui-component .table-dark td,
.dapp-core-ui-component .table-dark thead th,
.dapp-core-ui-component .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .dapp-core-ui-component .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.dapp-core-ui-component html {
  min-height: 100%;
  font-size: 14px;
}
.dapp-core-ui-component a,
.dapp-core-ui-component .link-style {
  color: #1392ff;
}
.dapp-core-ui-component .link-second-style {
  color: #9dabbd;
}
.dapp-core-ui-component .link-second-style:hover {
  color: #1392ff;
}
.dapp-core-ui-component .link-second-style:hover svg {
  color: #1392ff;
}
.dapp-core-ui-component .wrapper {
  min-height: 100vh;
}
.dapp-core-ui-component .opacity-6 {
  opacity: 0.6;
}
.dapp-core-ui-component .dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: #fafafa;
  width: toRem(65);
  height: toRem(65);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.dapp-core-ui-component .dapp-icon.icon-medium {
  width: 80px;
  height: 80px;
}
.dapp-core-ui-component .centering {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dapp-core-ui-component .border-n {
  border: none !important;
}
.dapp-core-ui-component .text {
  color: #9dabbd;
}
.dapp-core-ui-component .t-shadow {
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}
.dapp-core-ui-component .btn {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04) !important;
}
.dapp-core-ui-component .media {
  display: flex;
  align-items: flex-start;
}
.dapp-core-ui-component .media-body {
  flex: 1;
}
.dapp-core-ui-component .connect-btns .btn {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #1392ff;
  padding: 15px 20px 15px 16px;
  border-radius: 6px;
  gap: 8px;
}
.dapp-core-ui-component .connect-btns .btn:hover, .dapp-core-ui-component .connect-btns .btn:active {
  background-color: #1392ff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}
.dapp-core-ui-component .connect-btns .info {
  color: #1392ff;
  margin-left: 48px;
}
.dapp-core-ui-component .btn {
  transition: none !important;
}
.dapp-core-ui-component .trust-badge {
  width: 20px;
  height: 20px;
}
.dapp-core-ui-component .navbar .dapp-name {
  display: flex;
  align-items: center;
  font-size: toRem(24);
  font-weight: 400;
  color: #9ba5b4;
  margin-left: 1.6428571429rem;
}
.dapp-core-ui-component .navbar .dapp-name:before {
  content: "";
  display: block;
  width: 1px;
  height: toRem(42);
  margin-right: toRem(24);
}
.dapp-core-ui-component .action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 7px;
}
.dapp-core-ui-component .action-btn button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #fff;
}
.dapp-core-ui-component .action-btn button:hover {
  opacity: 0.9;
}
.dapp-core-ui-component .transactions .transaction-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.dapp-core-ui-component .transactions.table td {
  white-space: nowrap;
  vertical-align: middle;
}
.dapp-core-ui-component .transactions.table tr:last-of-type td {
  border-bottom: 1px solid #dee2e6;
}
.dapp-core-ui-component .page-state .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.dapp-core-ui-component .page-state .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.dapp-core-ui-component .page-state .bg-blue .lds-ellipsis div {
  background: rgba(255, 255, 255, 0.4);
}
.dapp-core-ui-component .page-state .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.dapp-core-ui-component .page-state .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.dapp-core-ui-component .page-state .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.dapp-core-ui-component .page-state .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.dapp-core-ui-component *,
.dapp-core-ui-component *::before,
.dapp-core-ui-component *::after {
  box-sizing: border-box;
}
.dapp-core-ui-component html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dapp-core-ui-component article, .dapp-core-ui-component aside, .dapp-core-ui-component figcaption, .dapp-core-ui-component figure, .dapp-core-ui-component footer, .dapp-core-ui-component header, .dapp-core-ui-component hgroup, .dapp-core-ui-component main, .dapp-core-ui-component nav, .dapp-core-ui-component section {
  display: block;
}
.dapp-core-ui-component body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.dapp-core-ui-component [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
.dapp-core-ui-component hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.dapp-core-ui-component h1, .dapp-core-ui-component h2, .dapp-core-ui-component h3, .dapp-core-ui-component h4, .dapp-core-ui-component h5, .dapp-core-ui-component h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dapp-core-ui-component p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dapp-core-ui-component abbr[title],
.dapp-core-ui-component abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
.dapp-core-ui-component address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.dapp-core-ui-component ol,
.dapp-core-ui-component ul,
.dapp-core-ui-component dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.dapp-core-ui-component ol ol,
.dapp-core-ui-component ul ul,
.dapp-core-ui-component ol ul,
.dapp-core-ui-component ul ol {
  margin-bottom: 0;
}
.dapp-core-ui-component dt {
  font-weight: 700;
}
.dapp-core-ui-component dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.dapp-core-ui-component blockquote {
  margin: 0 0 1rem;
}
.dapp-core-ui-component b,
.dapp-core-ui-component strong {
  font-weight: bolder;
}
.dapp-core-ui-component small {
  font-size: 80%;
}
.dapp-core-ui-component sub,
.dapp-core-ui-component sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.dapp-core-ui-component sub {
  bottom: -0.25em;
}
.dapp-core-ui-component sup {
  top: -0.5em;
}
.dapp-core-ui-component a {
  color: #1b46c2;
  text-decoration: none;
  background-color: transparent;
}
.dapp-core-ui-component a:hover {
  color: #122e7f;
  text-decoration: underline;
}
.dapp-core-ui-component a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
.dapp-core-ui-component a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.dapp-core-ui-component pre,
.dapp-core-ui-component code,
.dapp-core-ui-component kbd,
.dapp-core-ui-component samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
.dapp-core-ui-component pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
.dapp-core-ui-component figure {
  margin: 0 0 1rem;
}
.dapp-core-ui-component img {
  vertical-align: middle;
  border-style: none;
}
.dapp-core-ui-component svg {
  overflow: hidden;
  vertical-align: middle;
}
.dapp-core-ui-component table {
  border-collapse: collapse;
}
.dapp-core-ui-component caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
.dapp-core-ui-component th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.dapp-core-ui-component label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.dapp-core-ui-component button {
  border-radius: 0;
}
.dapp-core-ui-component button:focus:not(:focus-visible) {
  outline: 0;
}
.dapp-core-ui-component input,
.dapp-core-ui-component button,
.dapp-core-ui-component select,
.dapp-core-ui-component optgroup,
.dapp-core-ui-component textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.dapp-core-ui-component button,
.dapp-core-ui-component input {
  overflow: visible;
}
.dapp-core-ui-component button,
.dapp-core-ui-component select {
  text-transform: none;
}
.dapp-core-ui-component [role=button] {
  cursor: pointer;
}
.dapp-core-ui-component select {
  word-wrap: normal;
}
.dapp-core-ui-component button,
.dapp-core-ui-component [type=button],
.dapp-core-ui-component [type=reset],
.dapp-core-ui-component [type=submit] {
  -webkit-appearance: button;
}
.dapp-core-ui-component button:not(:disabled),
.dapp-core-ui-component [type=button]:not(:disabled),
.dapp-core-ui-component [type=reset]:not(:disabled),
.dapp-core-ui-component [type=submit]:not(:disabled) {
  cursor: pointer;
}
.dapp-core-ui-component button::-moz-focus-inner,
.dapp-core-ui-component [type=button]::-moz-focus-inner,
.dapp-core-ui-component [type=reset]::-moz-focus-inner,
.dapp-core-ui-component [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.dapp-core-ui-component input[type=radio],
.dapp-core-ui-component input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.dapp-core-ui-component textarea {
  overflow: auto;
  resize: vertical;
}
.dapp-core-ui-component fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.dapp-core-ui-component legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.dapp-core-ui-component progress {
  vertical-align: baseline;
}
.dapp-core-ui-component [type=number]::-webkit-inner-spin-button,
.dapp-core-ui-component [type=number]::-webkit-outer-spin-button {
  height: auto;
}
.dapp-core-ui-component [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
.dapp-core-ui-component [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.dapp-core-ui-component ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.dapp-core-ui-component output {
  display: inline-block;
}
.dapp-core-ui-component summary {
  display: list-item;
  cursor: pointer;
}
.dapp-core-ui-component template {
  display: none;
}
.dapp-core-ui-component [hidden] {
  display: none !important;
}
.dapp-core-ui-component .trim {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  /* IE fix */
  /* SAFARI 10.1+ fix */
}
.dapp-core-ui-component .trim.overflow .ellipsis {
  display: block;
}
.dapp-core-ui-component .trim .left {
  flex-shrink: 1;
  font-size: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dapp-core-ui-component .trim .right {
  flex-shrink: 1;
  font-size: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}
.dapp-core-ui-component .trim .left span,
.dapp-core-ui-component .trim .right span {
  font-size: 1rem;
  pointer-events: none;
  user-select: none;
}
.dapp-core-ui-component .trim .ellipsis {
  flex-shrink: 0;
  display: none;
  pointer-events: none;
  user-select: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dapp-core-ui-component .trim .right {
    text-overflow: clip;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .dapp-core-ui-component .trim .right {
      text-overflow: unset;
    }
  }
}
.dapp-core-ui-component .trim .hidden-text-ref {
  position: absolute;
  display: block;
  color: transparent;
}
.dapp-core-ui-component .trim-wrapper {
  display: flex;
  max-width: 100%;
  overflow: hidden;
}
.dapp-core-ui-component a:hover > .trim span {
  color: #122e7f !important;
}
.dapp-core-ui-component a:hover > .trim span.hidden-text-ref {
  color: transparent !important;
}
.dapp-core-ui-component a > .trim span,
.dapp-core-ui-component .text-primary > .trim span {
  color: #1b46c2 !important;
}
.dapp-core-ui-component a > .trim span.hidden-text-ref,
.dapp-core-ui-component .text-primary > .trim span.hidden-text-ref {
  color: transparent !important;
}
.dapp-core-ui-component .table .trim {
  max-width: 10rem;
}
.dapp-core-ui-component .table .trim-only-sm .trim {
  max-width: none;
}
@media (max-width: 767.98px) {
  .dapp-core-ui-component .table .trim-only-sm .trim {
    max-width: 13rem;
  }
}
.dapp-core-ui-component .trim-fs-sm .trim .left span,
.dapp-core-ui-component .trim-fs-sm .trim .right span,
.dapp-core-ui-component .trim-fs-sm .trim .ellipsis {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .dapp-core-ui-component .table .trim-size-xl .trim {
    max-width: 13rem;
  }
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-content {
  border-radius: 20px !important;
  border: none;
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-content .card {
  border: none;
  background: none;
  box-shadow: unset;
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-content .card-body {
  padding: 40px !important;
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-content .btn-close-link {
  width: 8rem;
  background: #f3f9ff;
  border: 1px solid #ddeeff;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-content .btn-close-link:hover {
  background-image: linear-gradient(rgba(77, 169, 255, 0.1) 0 0);
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-card .modal-card-body {
  border-radius: 0.25rem;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .modal-container .modal-dialog .modal-card {
    width: 39rem;
  }
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-card .card {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none !important;
  border: none;
  width: unset;
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-card .card > .p-4 {
  padding: 0 !important;
}
.dapp-core-ui-component .modal-container .modal-dialog .modal-card .card.my-4 {
  margin: 0 !important;
}
.dapp-core-ui-component .modal-container .modal-action-btns {
  display: flex;
  justify-content: center;
  gap: 11px;
  margin-top: 32px;
}
.dapp-core-ui-component .modal-container .modal-action-btns button {
  flex: 1;
  display: flex;
  max-width: 236px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background-color: #1392ff;
  border: 1px solid #007ded !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  gap: 12px;
}
.dapp-core-ui-component .modal-container .modal-action-btns button:hover, .dapp-core-ui-component .modal-container .modal-action-btns button:active {
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}
.dapp-core-ui-component .modal-container .modal-action-btns .selectable-option {
  max-width: 100% !important;
}
.dapp-core-ui-component .modal-container .modal-action-btns .btn-light {
  color: #1392ff;
  background: #f3f9ff;
  border: 1px solid #ddeeff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}
.dapp-core-ui-component .modal-container .modal-action-btns .btn-light:hover, .dapp-core-ui-component .modal-container .modal-action-btns .btn-light:active {
  color: #1392ff;
  background-image: linear-gradient(rgba(77, 169, 255, 0.1) 0 0);
  background-color: unset;
}
.dapp-core-ui-component .icon-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.dapp-core-ui-component .icon-state.half {
  width: 3rem;
  height: 3rem;
}
.dapp-core-ui-component .toast-messages {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 9999;
}
@media (min-width: 768px) {
  .dapp-core-ui-component .toast-messages {
    right: 2rem;
    bottom: 2rem;
    width: 22.5rem;
    left: auto;
  }
}
.dapp-core-ui-component .toast-messages .toast {
  display: block;
  flex-basis: 4rem;
  width: 100%;
  max-width: 28.875rem;
  transition: transform 0.3s ease-in-out;
  transform: translateX(120%);
  background-color: #fff;
  border-radius: 0.25rem;
  border-color: #dee2e6;
}
.dapp-core-ui-component .toast-messages .toast.clickable {
  cursor: pointer;
}
.dapp-core-ui-component .toast-messages .toast.toast-visible {
  margin-bottom: 0.3rem;
  transform: translateX(0);
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress {
  background-color: transparent;
  height: inherit;
  font-size: inherit;
  line-height: inherit;
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress .progress-bar {
  background-color: rgba(108, 117, 125, 0.2);
}
.dapp-core-ui-component .toast-messages .toast.toast-visible .progress .progress-bar .content-height {
  z-index: -1;
  visibility: hidden;
  width: 0 !important;
}
@media (max-width: 767.98px) {
  .dapp-core-ui-component .toast-messages .toast {
    display: none;
  }
  .dapp-core-ui-component .toast-messages .toast:first-child {
    display: block;
    transform: translateY(150%);
  }
  .dapp-core-ui-component .toast-messages .toast:first-child.toast-visible {
    transform: translateY(0);
  }
}
.dapp-core-ui-component .toast-messages .toast .tx-description {
  margin-bottom: 0.2rem;
}
.dapp-core-ui-component .toast-messages .close {
  opacity: 1;
}
.dapp-core-ui-component .toast-messages .close svg {
  color: #6c757d;
}
.dapp-core-ui-component .tx-details-text {
  width: 10rem;
}
.dapp-core-ui-component .token-symbol,
.dapp-core-ui-component .token-symbol-combined {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}
.dapp-core-ui-component .token-symbol svg #egld-token,
.dapp-core-ui-component .token-symbol-combined svg #egld-token {
  fill: #000;
}
.dapp-core-ui-component .token-symbol-combined {
  background-color: transparent;
}
.dapp-core-ui-component .token-symbol {
  border: 1px solid #000;
  width: 2rem;
  height: 2rem;
}
.dapp-core-ui-component .token-symbol img {
  width: 100%;
  height: 100%;
}
.dapp-core-ui-component .token-symbol svg {
  width: 1.25rem;
  height: 1.25rem;
}
.dapp-core-ui-component .token-symbol svg.expanded {
  width: 100% !important;
  height: 100% !important;
}
.dapp-core-ui-component .token-symbol-combined {
  width: 4.5rem;
  height: 4.5rem;
}
.dapp-core-ui-component .token-symbol-combined svg circle {
  fill: #fff;
}
.dapp-core-ui-component .token-symbol-combined svg {
  width: 4.25rem;
  height: 4.25rem;
}
.dapp-core-ui-component .token-symbol-combined.small {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
}
.dapp-core-ui-component .token-symbol-combined.small svg {
  width: 2.5rem;
  height: 2.5rem;
}
.dapp-core-ui-component .token-icon-lg .token-symbol {
  width: 3rem;
  height: 3rem;
}
.dapp-core-ui-component .token-icon-lg .token-symbol svg {
  width: 2.25rem;
  height: 2.25rem;
}
.dapp-core-ui-component .token-icon-xl .token-symbol {
  width: 4rem;
  height: 4rem;
}
.dapp-core-ui-component .token-icon-xl .token-symbol svg {
  width: 2.3rem;
  height: 2.3rem;
}
.dapp-core-ui-component .pool-icon.icon-xs .token-symbol + .token-symbol {
  margin-left: 0.7rem;
  margin-top: -0.325rem;
}
.dapp-core-ui-component .pool-icon.icon-xs .token-symbol {
  width: 1rem;
  height: 1rem;
}
.dapp-core-ui-component .pool-icon.icon-xs .token-symbol svg {
  width: 0.625rem;
  height: 0.625rem;
}
.dapp-core-ui-component .pool-icon.icon-md .token-symbol + .token-symbol {
  margin-left: 1.2rem;
  margin-top: -0.6rem;
}
.dapp-core-ui-component .pool-icon.icon-md .token-symbol {
  width: 1.8rem;
  height: 1.8rem;
}
.dapp-core-ui-component .pool-icon.icon-md .token-symbol svg {
  width: 1.2rem;
  height: 1.2rem;
}
.dapp-core-ui-component .pool-icon.icon-lg .token-symbol + .token-symbol {
  margin-left: 1.3rem;
  margin-top: -0.725rem;
}
.dapp-core-ui-component .pool-icon.icon-lg .token-symbol {
  width: 2.3rem;
  height: 2.3rem;
}
.dapp-core-ui-component .pool-icon.icon-xl .token-symbol + .token-symbol {
  margin-left: 2rem;
  margin-top: -1rem;
}
.dapp-core-ui-component .pool-icon.icon-xl .token-symbol {
  width: 4rem;
  height: 4rem;
}
.dapp-core-ui-component .pool-icon.icon-xl .token-symbol svg {
  width: 2.3rem;
  height: 2.3rem;
}
.dapp-core-ui-component .data-field .textarea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 5px;
  font: medium -moz-fixed;
  font-size: 0.8125rem;
  min-height: 5rem;
  overflow: auto;
  padding: 0.375rem 0.75rem;
  resize: both;
}
.dapp-core-ui-component .data-field .textarea:hover {
  border: 1px solid #000;
}
.dapp-core-ui-component .data-field .textarea .highlighted {
  font-size: 1rem;
}
.dapp-core-ui-component .data-field .textarea.sc-call {
  min-height: 3rem;
}
.dapp-core-ui-component .data-field .form-control:disabled,
.dapp-core-ui-component .data-field .form-control[readonly] {
  background-color: #fafafa;
}
.dapp-core-ui-component .data-field .small-font {
  font-size: 0.8125rem;
}
@media (min-width: 576px) {
  .dapp-core-ui-component .data-field {
    max-width: 39rem;
  }
}
.dapp-core-ui-component .progress-steps .steps hr {
  border-color: #dee2e6;
  z-index: 2;
}
.dapp-core-ui-component .progress-steps .steps .dot {
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  margin: 0 1rem;
  z-index: 5;
}
.dapp-core-ui-component .progress-steps .steps .dot:first-of-type {
  margin-left: 0;
}
.dapp-core-ui-component .progress-steps .steps .dot:last-of-type {
  margin-right: 0;
}